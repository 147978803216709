<template>
  <el-switch
    v-model="issOnValue"
    class="switch"
    style="display: block"
    active-color="#ee2e2f"
    inactive-color="#aaaaaa"
    :active-text="isOnValue ? '隐私' : '公开'"
    @change="change"
  >
  </el-switch>

</template>
<script>
export default {
  name: 'ZLSwitch',
  props: {
    isOnValue: Boolean,
    parentSwitchEvent: {
      type: Function,
      default: () => {
        return Function
      }
    }
  },
  data() {
    return {
      issOnValue: !this.isOnValue
    }
  },
  watch: {
    'isOnValue'(newV) {
      this.issOnValue = !newV
    }
  },
  methods: {
    change() {
      this.parentSwitchEvent()
    }
  }
}
</script>
<style lang="scss" scoped>
.switch /deep/.el-switch__label {
  font-size: 14px !important;
  color: #999999 !important;
}
</style>
