<template>
  <div  class="div_reigsit_outer">
    <a-tabs :default-active-key="pageType" @change="handleTabChange">
    <a-tab-pane key="1">
      <span slot="tab">
        注册信息
      </span>
      <div id="reigsitInfo" class="div_reigsit">
    <p class="myclass-title">
      <span class="title-text">注册信息</span>
      <span class="title-border"></span>
    </p>
    <ul>
      <li class="page_li" style="height:64px">
        <span style="line-height: 64px;padding-right: 10px">
          <p class="p_xh">*</p>修改头像:
        </span>
        <div class="div_icon">
          <!-- <el-upload class="avatar-uploader" action="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img
              id="iv_icon"
              onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'"
              class="iv_icon"
              :src="resultData.result.avatar"
            >
            <div class="mask div_edi_user_icon" style="cursor:pointer;">修改头像</div>
          </el-upload> -->

          <my-upload
            v-if="show_box"
            ref="myUpload"
            :model-value.sync="show_box"
            field="img"
            :no-square="true"
            img-format="jpg"
            :size="size"
            url="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar"
            @crop-upload-success="handleAvatarSuccess"
          ></my-upload>
          <div class="head" @click="toggleShow">
            <img :src="resultData.result.avatar" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
            <div class="mask div_edi_user_icon" style="cursor:pointer;">修改头像</div>
          </div>
        </div>
      </li>
      <li class="page_li">
        <span>
          <p class="p_xh">*</p>用户名：
        </span>
        <input v-model="paramRegister.username" class="input" type="text" placeholder="请填写用户名">
        <span class="explain">（用于个人主页）</span>
      </li>
      <li class="page_li">
        <span>
          <p class="p_xh">*</p>真实姓名：
        </span>
        <input v-model="paramRegister.realname" class="input" type="text" placeholder="请填写真实姓名">
      </li>
      <li class="page_li">
        <span>
          <p class="p_xh">*</p>绑定手机号：
        </span>
        <span class="span_tel" style="color: #ee2e2e;text-align: left">{{ resultData.result.mobile }}</span>
        <!-- <button class="btn_update_tel" @click="isShowBindPhoneDialog(1)">{{ bindPhoneBtnName }}</button> -->
      </li>
      <li class="page_li">
        <span>
          <p class="p_xh">*</p>绑定微信：
        </span>
        <span class="span_tel" style="color: #ee2e2e;text-align: left">{{ bindInfoObj.is_bind_weixin ? bindInfoObj.wx_nickname || '微信昵称' : '未绑定' }}</span>
        <!-- <button class="btn_update_tel" @click="isShowBindNicknameDialog(1)">{{ bindInfoObj.is_bind_weixin ? '申请换绑' : '绑定' }}</button> -->
        <!-- <button class="btn_update_tel" @click="removeBindData()">解除绑定</button>
        <button class="btn_update_tel" @click="removeBindData2()">换绑记录</button> -->
      </li>

      <li class="page_li">
        <span>
          <p class="p_xh">*</p>性别：
        </span>
        <div class="radio" :class="{checked:isChooseMan,noChecked:!isChooseMan}" @click="changeSex(0)">男
        </div>
        <div class="radio" :class="{checked:!isChooseMan,noChecked:isChooseMan}" @click="changeSex(1)">女
        </div>
      </li>
      <li class="page_li">
        <span>生日：</span>
        <el-date-picker
          v-model="resultData.result.birthday"
          class="select_calss1"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <!-- <select v-model="year">
          <template v-for="item in this.years">
            <option v-if="item === parseInt(year)" :value="item" selected="selected">
              {{ item }}
            </option>
            <option v-else :value="item">
              {{ item }}
            </option>
          </template>
        </select>
        <select v-model="month">
          <template v-for="(item,index) in 12">
            <option v-if="index>=9&&item === month" :key="item.id" :value="item" selected="selected">
              {{ item }}
            </option>
            <option v-else-if="index>=9" :key="item.id" :value="item">{{ item }}
            </option>
            <option v-else-if="index<9 && item === month" :key="item.id" :value="item" selected="selected">
              0{{ item }}
            </option>
            <option v-else-if="index<9" :key="item.id" :value="item">0{{ item }}
            </option>
          </template>

        </select>
        <select v-model="day">
          <template v-for="(item,index) in 31">
            <option v-if="index>=9&&item === day" :key="item.id" :value="item" selected="selected">{{ item }}
            </option>
            <option v-else-if="index>=9" :key="item.id" :value="item">{{ item }}
            </option>
            <option v-else-if="index<9 &&item === day" :key="item.id" :value="item" selected="selected">
              0{{ item }}
            </option>
            <option v-else-if="index<9" :key="item.id" :value="item">0{{ item }}
            </option>
          </template>
        </select> -->
      </li>
      <li class="page_li">
        <span>
          <p class="p_xh">*</p>兴趣点
        </span>
        <input v-model="majorName" class="input" type="text" placeholder="请选择专业" readonly="readonly" @click="showMajorDialog(1)">
      </li>
      <li class="page_li">
        <span>地区：</span>
        <input v-model="provinceAndCityName" class="input" readonly="readonly" type="text" placeholder="非必填" @click="isShowCityDialog(1)">
      </li>

      <li class="page_li">
        <span>学校：</span>
        <input v-model="paramRegister.school_name" class="input" type="text" placeholder="非必填">
      </li>
      <li class="page_li">
        <span>所学专业：</span>
        <input v-model="paramRegister.major" class="input" type="text" placeholder="非必填">
      </li>
      <li class="page_li">
        <span>学历：</span>
        <el-select v-model="value4" class="el_select" clearable placeholder="请选择您的学历" @change="currentSel">
          <el-option v-for="item in options" :key="item.value" class="item" :popper-append-to-body="false" :label="item.label" :value="item">
          </el-option>
        </el-select>
      </li>
      <li class="page_li">
        <span>毕业时间：</span>
        <el-date-picker
          v-model="resultData.result.graduation_time"
          class="select_calss1"
          type="year"
          placeholder="选择日期"
          value-format="yyyy"
        >
        </el-date-picker>
        <!-- <select v-model="resultData.result.graduation_time" style="width:302px;text-align:left;padding-left:24px" class="select_calss">
          <template v-for="item in this.years">
            <option v-if="item === parseInt(year)" :value="item" selected="selected">
              {{ item }}
            </option>
            <option v-else :value="item">
              {{ item }}
            </option>
          </template>
        </select> -->
        <!-- <select v-model="monthG">
          <template v-for="(item,index) in 12">
            <option v-if="index>=9&&item === month" :key="item.id" :value="item" selected="selected">
              {{ item }}
            </option>
            <option v-else-if="index>=9" :key="item.id" :value="item">{{ item }}
            </option>
            <option v-else-if="index<9 && item === month" :key="item.id" :value="item" selected="selected">
              0{{ item }}
            </option>
            <option v-else-if="index<9" :key="item.id" :value="item">0{{ item }}
            </option>
          </template>

        </select>
        <select v-model="dayG">
          <template v-for="(item,index) in 31">
            <option v-if="index>=9&&item === day" :key="item.id" :value="item" selected="selected">{{ item }}
            </option>
            <option v-else-if="index>=9" :key="item.id" :value="item">{{ item }}
            </option>
            <option v-else-if="index<9 &&item === day" :key="item.id" :value="item" selected="selected">
              0{{ item }}
            </option>
            <option v-else-if="index<9" :key="item.id" :value="item">0{{ item }}
            </option>
          </template>
        </select> -->
      </li>
      <li class="page_li">
        <span>公司名称：</span>
        <input v-model="resultData.result.company" class="input" type="text" placeholder="非必填">
      </li>
      <li class="page_li" style="height:auto">
        <span>资格证书：</span>
        <input
          v-for="(item,index) in resultData.result.certificate||1"
          :key="index"
          v-model="resultData.result.certificate[index]"
          class="input"
          type="text"
          placeholder="非必填"
          :style="index>0?'display:block;margin-left:182px;margin-top:12px':'display:block'"
        >
      </li>
      <div class="addNew" :style="resultData.result.certificate.length>0?'margin-top:12px':'margin-top:37px'"><span style="cursor: pointer;" @click="resultData.result.certificate.push('')"><i class="el-icon-plus"></i>添加证书</span></div>
      <li class="page_li" style="height:auto">
        <span>添加标签：</span>
        <input
          v-for="(item,index) in resultData.result.user_tags||1"
          :key="index"
          v-model="resultData.result.user_tags[index]"
          class="input"
          type="text"
          placeholder="非必填"
          :style="index>0?'display:block;margin-left:182px;margin-top:12px':'display:block'"
        >
      </li>
      <div class="addNew" :style="resultData.result.user_tags.length>0?'margin-top:12px':'margin-top:37px'"><span style="cursor: pointer;" @click="resultData.result.user_tags.push('')"><i class="el-icon-plus"></i>添加标签</span></div>
      <li class="page_li_">
        <span>个人简介：</span>
        <el-input
          v-model="resultData.result.intro"
          type="textarea"
          class="textarea"
          :autosize="{ minRows: 3, maxRows: 8}"
          rows="4"
          placeholder="非必填"
        >
        </el-input>
      </li>
      <li class="page_li">
        <span>邮箱：</span>
        <input v-model="paramRegister.email" class="input" type="text" placeholder="请填写您的邮箱">
        <p v-show="false" class="email_tip" style="display: inline-block">提示：稍候我们将下发激活链接至邮箱,为不影响在本站使用，请前往激活</p>
      </li>
      <!-- <li class="page_li">
        <span><p class="p_xh">*</p>邮箱验证码：</span>
        <div class="dialogBindCheckedCode" style="margin-left: 0px; margin-top: 0px">
          <input v-model="bindEmail.code" type="text" maxlength="8" placeholder="请输入验证码">
          <span class="line" style="width: 1px;margin-left: 56px" />
          <p class="getCode" style="margin-left:4px" @click="sendEmailLink()">获取验证码</p>
        </div>
        <p v-show="showEamilTip" class="email_tip" style="margin-bottom:36px;">已发送验证码至邮箱</p>

      </li> -->
    </ul>

    <button class="btn_save_info" @click="saveRigstInfo(paramRegister)">保存</button>

    <!--地选择城市弹窗-->
    <div v-show="isShowCityPop" id="pop_province" class="chooseCity chooseCommon">
      <div class="zhul_diq_jiao" />
      <p class="dialogCommonP chooseP">
        <span>所在地区</span>&nbsp;
        <a v-if="this.cityLevel>1" class="chooseA" href="javascript:void (0)" @click="backProvince()">[返回上一级]</a>
        <img class="dialogCommonImg" src="../../assets/image/close.gif" @click="isShowCityDialog(0)">
      </p>
      <div class="chooseDiv" style="margin: 0">
        <ul>

          <li v-for="item in provinceData" v-if="cityLevel===1" :key="item.id" class="chooseCityLi chooseDivLi">
            <a class="chooseDivA" @click="showCity(item.id,item.name)">{{ item.name }}</a>
          </li>

          <li v-for="item1 in cityData" v-if="cityLevel===2" class="chooseCityLi chooseDivLi">
            <a class="chooseDivA" @click="showCity(item1.id,item1.name)">{{ item1.name }}</a>
          </li>

          <li v-for="item2 in areaData" v-if="cityLevel===3" class="chooseCityLi chooseDivLi">
            <a class="chooseDivA" @click="showCity(item2.id,item2.name)">{{ item2.name }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- <el-dialog title="收货地址"  :visible.sync="isShowMajor">
             <div class="zhul_diq_jiao"/>
             <p class="dialogCommonP chooseP">
                 <span>选择专业</span>&nbsp;
                 <img class="dialogCommonImg" src="../../../assets/image/close.gif"
                      @click="showMajorDialog(0)">
             </p>
             <div  style="margin: 0 ;height: 200px;overflow:auto">
                 <ul>
                     <li v-for="item in 100">{{item}}</li>
                 </ul>
             </div>
             <div slot="footer" class="dialog-footer">
                 <el-button @click="isShowMajor = false">取 消</el-button>
                 <el-button type="primary" @click="isShowMajor = false">确 定</el-button>
             </div>
         </el-dialog>-->
    <!--地选择专业弹窗-->
    <div v-show="isShowMajor" class="chooseMajor chooseCommon">
      <div class="zhul_diq_jiao" />
      <p class="dialogCommonP chooseP">
        <span>选择专业</span>&nbsp;
        <img class="dialogCommonImg" src="../../assets/image/close.gif" @click="showMajorDialog(0)">
      </p>
      <div class="chooseDiv" style="margin: 0px;max-height:360px;overflow: auto;overflow-x: hidden;">
        <ul>
          <li v-for="(item,index1) in resultMajor.result.list" :key="item.id" class="clearfix chooseMajorLi">
            <p align="left">{{ item.category_name }}</p>
            <dl v-for="item in resultMajor.result.list[index1].childList" :key="item.id" class="chooseDivLi chooseMajorDl">
              <a class="chooseDivA" @click="showMajorDialog(0,item.id,item.category_name)">
                {{ item.category_name }}
              </a>
            </dl>
          </li>
        </ul>
      </div>
    </div>

    <!--手机绑定-->
    <div v-show="isShowBindPhonePop" class="dialogCommon dialogBindPhone">
      <p class="dialogCommonP">
        <span class="dialogBindPhoneTitle">手机绑定</span>&nbsp;
        <img class="dialogCommonImg" style="width: 20px;height:20px;margin-top: 10px" src="../../assets/image/white_close.png" alt="close" @click="isShowBindPhoneDialog(0)">
      </p>
      <div style="margin: 0">
        <ul>
          <li v-show="showBindPhoneTitle">
            <span>*当前绑定手机号：</span>
            <span v-cloak>{{ bindPhone }}</span>
          </li>
          <li>
            <span>*手机号码：</span>
            <label>
              <input v-model="paramsBindPhone.mobile" class="input" type="text" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号">
            </label>
          </li>
          <li>
            <span style="display: inline-block; position: relative; top: -10px ">*验证码：</span>
            <div class="dialogBindCheckedCode">
              <label>
                <input v-model="paramsBindPhone.code" type="text" maxlength="8" placeholder="请输入验证码">
              </label>
              <span class="line" />
              <input v-model="btnMsg == null ? countNum+'s 重新发送' : btnMsg" type="button" :disabled="countFlag" class="getCode" @click="getVerificationCode()">
            </div>
          </li>
        </ul>
        <button class="btn_bind_Phone" @click="submitBindPhone()">立即绑定</button>

      </div>
    </div>
    <!-- 微信绑定 -->
    <div v-show="isShowBindNicknamePop" class="dialogCommon dialogBindPhone dialogBindPhone_wx">
      <p class="dialogCommonP" style="text-align: center">
        <span class="dialogBindPhoneTitle" style="font-size: 16px">更换微信账号绑定</span>&nbsp;
        <img class="dialogCommonImg" style="width: 20px;height:20px;margin-top: 10px" src="../../assets/image/white_close.png" alt="close" @click="isShowBindNicknameDialog(0)">
      </p>
      <div style="margin: 10px 0 0">
        <p>
          请用新微信号扫一扫关注公众号
        </p>
        <p style="margin: 0 0 13px">
          重新关联
        </p>
        <p style="color: #ee2e2e;">
          每个账号一个月仅可换绑1次
        </p>
        <p v-if="bindInfoObj.change_bind_able == 0" style="color: #ee2e2e;margin: 13px 0 0;">
          本月已到达次数上限
        </p>
      </div>
      <div v-if="bindInfoObj.change_bind_able != 0" style="display: flex;justify-content: end;">
        <button class="btn_bind_Phone" style="marginTop: 23px;" @click="toLoginBing()">换绑微信</button>
        <button style="margin-left: 15px;margin-left: 15px;background: #fff;border: 1px solid #bbb;color: #333;" class="btn_bind_Phone" @click="isShowBindNicknameDialog(0)">取消</button>
      </div>
    </div>
    <div v-show="isShowYinYing" class="view_yiying" />
  </div>
    </a-tab-pane>
    <a-tab-pane key="2">
      <span slot="tab">
        档案
      </span>
      <p class="myclass-title">
      <span class="title-text">档案</span>
      <span class="title-border"></span>
    </p>
      <archive-component></archive-component>
    </a-tab-pane>
  </a-tabs>

  
  </div>
  
</template>
<script>
import Cookies from 'js-cookie'
// ESlint报错，原代码提交记录 caolinxing
import {
  bindPhone,
  getEduConfig,
  getMajorList,
  getProvinceCityList,
  // getRegistInfo,
  requestBindEmail,
  sendEmailCode,
  sendPhoneCode,
  updateIcon,
  updateRegistInfo,
  getRegisInfoByToken,
  regisInfoByToken
} from '@/api/mine'
import { sendMobileCode, bindMobile, getLoginUserInfo, removeBindData } from '@/api/login'
import { mapActions, mapMutations } from 'vuex'
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'
import archiveComponent from './archiveComponent.vue'

export default {
  components: {
    'my-upload': myUpload,
    'archive-component': archiveComponent
  },
  data() {
    return {
      pageType:this.$route.query.type,
      token: this.$route.query.token,
      // 倒计时周期
      countNum: 60,
      intervalBtn: null,
      // 用于倒计时标记，true-正在倒计时
      countFlag: false,
      // 默认按钮的值
      btnMsg: '获取验证码',
      showBindPhoneTitle: false,
      showEamilTip: false,
      bindPhoneBtnName: '绑定手机号',
      bindNicknameBtnName: '绑定',
      // 禁止多次点击
      canClick: true,
      cityLevel: 0,
      years: [],
      year: '',
      month: '',
      day: '',
      yearG: '',
      monthG: '',
      dayG: '',
      provinceAndCityName: '',
      provinceName: '',
      cityName: '',
      countyName: '',
      resultEduConfig: {
        errNo: 0,
        msg: '',
        result: [{ id: '', education: '' }]
      },
      options: [],
      formNewTask: this.$route.query.formNewTask || 0,
      value4: '',
      bindPhone: '',
      // 显示城市弹窗
      isShowCityPop: false,
      // 绑定手机号弹窗
      isShowBindPhonePop: false,
      // 绑定微信弹窗
      isShowBindNicknamePop: false,
      // 显示省列表
      isProvince: true,
      cityPosition: 0,
      // 选择男/女
      isChooseMan: true,
      // 显示专业弹窗
      isShowMajor: false,
      // 阴影
      isShowYinYing: false,
      // 生日：
      birthday: '',
      // 专业名称
      majorName: '',
      // 修改头像
      paramsUpdateIcon: {
        filename: ''
      },
      resultMajor: {
        errNo: 0,
        msg: '',
        result: {
          count: 287,
          list: [{
            id: '',
            parent_id: '',
            category_name: '',
            level: 1,
            path: '',
            description: '',
            childList: [],
            children: {
              '2': {
                id: '',
                parent_id: '',
                category_name: '',
                level: '',
                path: '',
                description: '',
                children: null
              }
            }
          }]
        }
      },

      provinceData: [{
        id: '',
        parent_id: '',
        name: ''
      }],
      cityData: [{
        id: '',
        parent_id: '',
        name: ''
      }],
      areaData: [{
        id: '',
        parent_id: '',
        name: '',
        level: ''
      }],
      // 绑定手机号入参
      paramsBindPhone: {
        mobile: '',
        code: ''
      },
      // 绑定手机号入参
      paramSendPhonePhone: {
        mobile: ''
      },
      // 获取邮箱验证码
      sendEmail: {
        email: ''
      },
      // 获取邮箱验证码
      bindEmail: {
        email: '',
        code: ''
      },
      // 保存注册信息入参
      paramRegister: {
        // 用户名
        username: '',
        // 真实姓名
        realname: '',
        // 性别
        gender: '',
        // 学校名称
        school_name: '',
        // 简介
        intro: '',
        // 生日
        birthday: '',
        // 专业id
        specialty_id: '',
        // 学历Id
        degree: '',
        // 省份id
        province_id: '',
        // 城市id
        city_id: '',
        // 区id
        county_id: '',
        // 邮箱
        email: '',
        // 所学专业
        major: ''

      },
      majorInfo: [],

      params_citys: {
        parent_id: ''
      },
      resultData: {
        errNo: 0,
        result: {
          avatar: require('../../assets/image/avatar.png'),
          username: '',
          realname: '',
          mobile: '',
          gender: '',
          birthday: '',
          specialty_id: '',
          province_id: '',
          province: '',
          city_id: '',
          city: '',
          school_name: '',
          specialty: '',
          degree: '',
          email: '',
          intro: '',
          company: '',
          certificate: [''],
          graduation_time: '',
          user_tags: ['']

        }
      },
      bindInfoObj: {
        wx_nickname: '',
        is_bind_weixin: '',
        change_bind_able: ''
      },
      show_box: false, // 剪切框显示和隐藏的flag
      size: 2.1

    }
  },

  computed: {
    // 控制显示的内容
    computedTxt() {
      return function(str) {
        return str.split('-')
      }
    }

  },

  created() {
    // 获取【年】数据
    const date = new Date()
    for (let i = 1900; i <= date.getFullYear(); i++) {
      this.years.unshift(i)
    }
    // 获取学历信息
    getEduConfig().then((res) => {
      this.resultEduConfig.errNo = res.errNo
      this.resultEduConfig.msg = res.msg
      this.resultEduConfig.result = res.result
      const result = this.resultEduConfig.result
      for (let i = 0; i < result.length; i++) {
        var obj = {
          value: '',
          label: ''
        }
        obj.value = result[i].id
        obj.label = result[i].education
        this.options.push(obj)
      }
    })
    // 获取省市列表
    this.getProvinceCity()
    // 获取注册信息
    this.getRegistInfo()
    // 获取专业列表
    this.getMajorInfo()
    // 获取微信号
    // this.getLoginUserInfo()
  },
  methods: {
    ...mapMutations(['storageToken']),
    ...mapActions({
      'cacheUsername': 'userInfo/storageTheUsername',
      'cacheUid': 'userInfo/storageTheUid',
      'cacheAvatar': 'userInfo/storageTheAvatar',
      'cacheVipLevel': 'userInfo/storageTheVipLevel'
    }),
    handleTabChange(val){
      this.$router.replace({
        path: this.$route.path,
        query: {
          token: this.$route.query.token,
          type: val
        }
      })
    },
    // 上传组件头像
    toggleShow() {
      this.show_box = !this.show_box
      // this.$refs.myUpload.setStep(1)
    },
    // 倒计时
    countDown() {
      console.log('计时器启动：' + this.countNum)
      // 设置btn倒计时时显示的信息
      this.btnMsg = null
      // 更改btn状态
      this.countFlag = !this.countFlag
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.btnMsg = '获取验证码'
          // 清除定时器
          clearInterval(this.intervalBtn)
          // 更改btn状态
          this.countFlag = !this.countFlag
          // 重置倒计时状态
          this.countNum = 60
        }

        // 倒计时
        this.countNum--
      }, 1000)
    },

    currentSel(selVal) {
      this.paramRegister.degree = selVal.value
    },
    // 修改用户头像
    handleAvatarSuccess(res, file) {
      this.show_box = false
      this.paramsUpdateIcon.filename = res.url
      this.paramsUpdateIcon.token = this.token
      updateIcon(this.paramsUpdateIcon).then((res) => {
        if (res.errNo === 0) {
          this.$message.success('头像修改成功')
          var avatar = res.result.result || ''
          avatar = avatar.split('?')[0]
          var timestamp = parseInt(new Date().getTime() / 1000)
          avatar = avatar + '?t=' + timestamp
          this.resultData.result.avatar = avatar
          console.log('修改头像成功 ', avatar)
          this.cacheAvatar({ avatar: avatar })
          location.reload()
        }
      })
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    successTip(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    warningTip(msg) {
      this.$message({
        message: msg,
        type: 'warning'
      })
    },
    normalTip(msg) {
      this.$message(msg)
    },
    errorTip(msg) {
      this.$message({
        message: msg,
        type: 'error'
      })
    },
    // 获取手机验证码
    getVerificationCode() {
      if (this.paramsBindPhone.mobile) {
        const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
        // 生成一个滑块验证码对象
        const that = this
        const captcha = new TencentCaptcha(appid, function(res) { // eslint-disable-line
          // 用户滑动结束或者关闭弹窗，腾讯返回的内容
          console.log(res, '------------res')
          if (res.ret == '0') {
            console.log(res, '------------res1')
            console.log('----------------------------------')
            that.paramSendPhonePhone.mobile = that.paramsBindPhone.mobile
            const info = {
              mobile: that.paramsBindPhone.mobile,
              code_type: 2,
              'randstr': res.randstr,
              'ticket': res.ticket
            }
            console.log(info, '-----------------------2-----------')

            sendMobileCode(info).then(res => {
              if (res.errNo === 0) {
                that.successTip('发送验证码成功')
                that.countDown()
              } else {
                that.errorTip(res.msg)
              }
            })
          } else {
            // 提示用户完成验证
          }
        })
        // 滑块显示
        captcha.show()
      }

      // sendPhoneCode(this.paramSendPhonePhone).then((res) => {
      //   if (res.errNo === 0) {
      //     this.successTip('发送验证码成功')
      //     this.countDown()
      //   } else {
      //     this.errorTip(res.msg)
      //   }
      // })
      // 触发定时器方法
    },
    /* 绑定手机*/
    submitBindPhone() {
      if (this.paramsBindPhone.mobile.length === 11 && this.paramsBindPhone.code.length >= 2) {
        const info = {
          uuid: Cookies.get('uuid'),
          mobile: this.paramsBindPhone.mobile,
          code: this.paramsBindPhone.code,
          access_token: Cookies.get('access_token')
        }
        bindMobile(info).then(res => {
          if (res.errNo === 0) {
            this.successTip('绑定手机号成功')
            this.resultData.result.mobile = this.paramsBindPhone.mobile
            this.isShowBindPhonePop = false
          } else {
            this.errorTip(res.msg)
          }
        })
        // bindPhone(this.paramsBindPhone).then((res) => {
        //   if (res.errNo === 0) {
        //     this.successTip('绑定手机号成功')
        //     this.resultData.result.mobile = this.paramsBindPhone.mobile
        //     this.isShowBindPhonePop = false
        //   } else {
        //     this.errorTip(res.msg)
        //   }
        // })
      } else {
        this.warningTip('请输入正确的验证码')
      }
    },
    sendEmailLink() {
      this.sendEmail.email = this.paramRegister.email
      sendEmailCode(this.sendEmail).then((res) => {
        if (res.errNo === 0) {
          this.showEamilTip = true
        } else {
          this.errorTip(res.msg)
        }
      })
    },

    bindEmailFun() {
      this.bindEmail.email = this.paramRegister.email
      if (this.bindEmail.code.length > 2) {
        requestBindEmail(this.bindEmail).then((res) => {
          if (res.errNo !== 0) {
            this.errorTip(res.msg)
          }
        })
      }
    },

    // 获取注册信息
    getRegistInfo() {
      // 获取学历信息
      const info = {
        token: this.token
      }
      console.log(info, 'infoinfo')
      getRegisInfoByToken(info).then((res) => {
        this.resultData.errNo = res.errNo
        this.resultData.msg = res.msg
        this.resultData.result = res.result

        if (res.result.certificate === '') {
          this.resultData.result.certificate = ['']
        } else {
          this.resultData.result.certificate = res.result.certificate.split(',')
          this.resultData.result.certificate = this.resultData.result.certificate.filter(item => item)
        }
        if (res.result.user_tags === '') {
          this.resultData.result.user_tags = ['']
        } else {
          this.resultData.result.user_tags = res.result.user_tags
        }
        const info = this.resultData.result
        if (info.mobile.length > 0) {
          this.bindPhone = info.mobile
          this.bindPhoneBtnName = '申请换号'
          this.showBindPhoneTitle = true
        } else {
          this.showBindPhoneTitle = false
          this.bindPhoneBtnName = '绑定手机号'
        }
        console.log(res.result.username, 'res.result.username')
        var avatar = res.result.avatar || ''
        avatar = avatar.split('?')[0]
        var timestamp = parseInt(new Date().getTime() / 1000)
        avatar = avatar + '?t=' + timestamp
        this.resultData.result.avatar = avatar
        console.log(avatar, 'avatar')
        this.cacheUsername({ username: res.result.username })
        this.cacheAvatar({ avatar: avatar })
        Cookies.set('username', res.result.username, { expires: 7 })
        this.paramRegister.username = info.username
        this.paramRegister.realname = info.realname
        var getGender = '0'
        if (info.gender == '男' || info.gender == 0) {
          getGender = '0'
        }
        if (info.gender == '女' || info.gender == 1) {
          getGender = '1'
        }
        this.paramRegister.gender = getGender
        this.paramRegister.degree = info.degree
        this.paramRegister.intro = info.intro
        this.paramRegister.major = info.major

        // 0男1女
        if (this.paramRegister.gender == 0) {
          this.isChooseMan = true
        } else {
          this.isChooseMan = false
        }

        this.bindInfoObj.wx_nickname = res.result.wx_nickname
        this.bindInfoObj.is_bind_weixin = res.result.is_bind_weixin
        this.bindInfoObj.change_bind_able = res.result.change_bind_able
        // if (info.graduation_time !== '') {
        //   const date = this.computedTxt(info.graduation_time)
        //   for (let i = 0; i < date.length; i++) {
        //     switch (i) {
        //       case 0:
        //         this.yearG = parseInt(date[i])
        //         break
        //       case 1:
        //         this.monthG = parseInt(date[i])
        //         break
        //       default:
        //         this.dayG = parseInt(date[i])
        //         break
        //     }
        //   }
        // }
        // const date = this.computedTxt(info.birthday)
        // for (let i = 0; i < date.length; i++) {
        //   switch (i) {
        //     case 0:
        //       this.year = date[i]
        //       break
        //     case 1:
        //       this.month = parseInt(date[i])
        //       break
        //     default:
        //       this.day = parseInt(date[i])
        //       break
        //   }
        // }
        // 生日
        this.paramRegister.birthday = info.birthday

        this.paramRegister.school_name = info.school_name
        this.paramRegister.specialty_id = info.specialty_id
        for (const i in this.resultEduConfig.result) {
          if (this.paramRegister.degree == this.resultEduConfig.result[i].id) {
            this.value4 = this.resultEduConfig.result[i].education
          }
        }
        this.paramRegister.province_id = info.province_id
        this.paramRegister.city_id = info.city_id
        this.paramRegister.county_id = info.county_id ? info.county_id : ''
        this.paramRegister.email = info.email
        this.majorName = info.specialty
        this.paramRegister.education = info.education
        this.provinceName = info.province ? info.province : ''
        this.cityName = info.city ? info.city : ''
        this.countyName = info.county ? info.county : ''
        this.provinceAndCityName = this.provinceName + ' ' + this.cityName + ' ' + this.countyName
      })
    },

    /* 地区列表*/
    getProvinceCity() {
      getProvinceCityList(this.params_citys).then((res) => {
        this.canClick = true
        if (parseInt(res.errNo) === 0) {
          if (this.cityLevel === 0) {
            this.provinceData = res.result
          } else if (this.cityLevel === 1) {
            this.isShowCityPop = true
            this.isShowYinYing = true
            this.cityData = res.result
          } else {
            this.isShowCityPop = true
            this.isShowYinYing = true
            this.areaData = res.result
          }
          this.cityLevel++
        }
      })
    },

    /* 获取专业*/
    getMajorInfo() {
      const params = {
        is_old: 1
      }
      getMajorList().then((res) => {
        if (res.errNo === 0) {
          this.resultMajor.msg = res.msg
          this.resultMajor.result = res.result
          const datas = this.resultMajor.result.list
          for (const i in datas) {
            const list = datas[i].children
            const childs = []
            for (const j in list) {
              const obj = {
                id: '',
                category_name: ''
              }
              obj.id = list[j].id
              obj.category_name = list[j].category_name
              childs.push(obj)
            }
            datas[i].childList = childs
          }
        }
      })
    },

    /* 保存注册信息*/
    saveRigstInfo(param) {
      /* //用户名
                    username: "",
                    //真实姓名
                    realname: "",
                    //性别
                    gender: "",
                    //学校名称
                    school_name: "",
                    //简介
                    intro: "",
                    //生日
                    birthday: "",
                    //专业id
                    specialty_id: "",*/
      if (!this.paramRegister.username) {
        this.warningTip('用户名不能为空')
        return
      }
      if (!this.paramRegister.realname) {
        this.warningTip('真实姓名不能为空')
        return
      }
      if (this.paramRegister.gender.length == 0) {
        this.warningTip('性别不能为空')
        return
      }
      // if (!this.paramRegister.birthday) {
      //   this.warningTip('生日不能为空')
      //   return
      // }
      if (!this.paramRegister.specialty_id) {
        this.warningTip('专业不能为空')
        return
      }
      const FloatRegex = /[^\u4e00-\u9fa5]/
      if (FloatRegex.test(this.paramRegister.realname)) {
        this.errorTip('请输入真实姓名')
        return
      }
      this.bindEmailFun()
      this.paramRegister.intro = this.resultData.result.intro
      this.paramRegister.company = this.resultData.result.company
      this.resultData.result.certificate = this.resultData.result.certificate.filter(item => item)
      this.paramRegister.certificate = this.resultData.result.certificate.join(',')
      this.paramRegister.user_tags = JSON.stringify(this.resultData.result.user_tags.filter((item) => { return item != '' }))
      this.paramRegister.graduation_time = this.resultData.result.graduation_time
      this.paramRegister.birthday = this.resultData.result.birthday
      this.paramRegister.token = this.token
      regisInfoByToken(this.paramRegister).then((res) => {
        if (res.errNo === 0) {
          this.successTip('修改注册信息成功')
          this.getRegistInfo()
          if (this.formNewTask) this.$router.push('taskcenter')
        } else {
          this.errorTip(res.msg)
        }
      })
    },
    /* 切换性别：0:男1：女*/
    changeSex(sex) {
      if (sex == '0') {
        this.isChooseMan = true
        this.paramRegister.gender = '0'
      } else {
        this.isChooseMan = false
        this.paramRegister.gender = '1'
      }
    },
    /* 绑定手机号弹窗*/
    isShowBindPhoneDialog(isShow) {
      if (isShow == '1') {
        this.isShowBindPhonePop = true
        this.isShowYinYing = true
      } else {
        this.isShowBindPhonePop = false
        this.isShowYinYing = false
      }
    },
    /* 绑定微信弹窗*/
    isShowBindNicknameDialog(isShow) {
      if (isShow == '1') {
        this.isShowBindNicknamePop = true
        this.isShowYinYing = true
      } else {
        this.isShowBindNicknamePop = false
        this.isShowYinYing = false
      }
    },
    /* 控制选择专业弹窗*/
    showMajorDialog(isShow, majorId, majorName) {
      if (isShow == 1) {
        // 显示
        this.isShowMajor = true
        // this.isShowYinYing = true;
      } else {
        // 不显示
        this.isShowMajor = false
        this.isShowYinYing = false
        if (majorId != null && majorName != null) {
          this.majorName = majorName
          this.paramRegister.specialty_id = majorId
          console.log(isShow + '---' + majorId + '---' + majorName)
        }
      }
    },

    /* 控制城市弹窗*/
    isShowCityDialog(isShow) {
      if (isShow != '1') {
        this.isShowCityPop = false
        this.isShowYinYing = false
        this.cityLevel = 1
      } else {
        this.isShowCityPop = true
        this.isShowYinYing = true
      }
    },
    /* 显示城市列表*/
    showCity(pid, cname) {
      console.log(this.cityLevel)
      console.log(pid + '  ' + cname)
      if (this.cityLevel === 1) {
        // 省
        this.paramRegister.province_id = pid
        this.paramRegister.city_id = ''
        this.paramRegister.county_id = ''

        this.provinceName = cname
        this.cityName = ''
        this.countyName = ''

        this.provinceAndCityName = this.provinceName

        // 请求城市
        this.params_citys.parent_id = pid
        this.canClick = false
        this.getProvinceCity()
      } else if (this.cityLevel === 2) {
        // 市
        this.paramRegister.city_id = pid
        this.paramRegister.county_id = ''
        this.cityName = cname
        this.countyName = ''
        this.provinceAndCityName = this.provinceName + '  ' + cname
        // 请求第三级
        this.params_citys.parent_id = pid
        this.canClick = false
        this.getProvinceCity()
      } else {
        // 第三级 选择完成
        this.paramRegister.county_id = pid
        this.countyName = cname
        this.county_id = pid
        this.provinceAndCityName = this.provinceName + '  ' + this.cityName + '  ' + cname
        this.isShowCityPop = false
        this.isShowYinYing = false
        this.cityLevel = 1
      }
    },
    /* 选择完毕*/
    /* 显示省市列表*/
    backProvince() {
      this.cityLevel--
    },
    // 微信绑定信息
    // getLoginUserInfo() {
    //   getLoginUserInfo().then(res => {
    //     if (res.errNo == 0) {
    //       const result = res.result
    //       this.bindInfoObj.wx_nickname = result.wx_nickname
    //       this.bindInfoObj.is_bind_weixin = result.is_bind_weixin
    //       this.bindInfoObj.change_bind_able = result.change_bind_able
    //     }
    //   })
    // },
    // 换绑微信
    toLoginBing() {
      this.$router.push({
        path: '/wxBindLogin'
      })
    },
    // 解除绑定
    removeBindData() {
      const info = {
        agency_id: 2,
        uid: Cookies.get('uid'),
        type: 1
      }
      removeBindData(info).then(res => {
        console.log(res, 'res')
      })
    },
    // 换绑记录
    removeBindData2() {
      const info = {
        agency_id: 2,
        uid: Cookies.get('uid'),
        type: 2
      }
      removeBindData(info).then(res => {
        console.log(res, 'res')
      })
    }

  }

}
</script>

<style lang='scss' scoped>
.div_reigsit_outer {
    margin: 80px auto 0;
    position: relative;
    width: 965px;
    // height: 700px;
    background-color: #fff;
    padding: 0 20px 20px 20px;
  }
  .div_reigsit {
    position: relative;
    width: 965px;
    // height: 700px;
    background-color: #fff;
    padding: 0 20px 20px 20px;
  }

  .page_li {
    position: relative;
    width: 100%;
    height: 46px;
    margin-top: 25px;
    text-align: left;
  }
  .page_li_ {
    position: relative;
    width: 100%;
    // height: 46px;
    margin-top: 25px;
    text-align: left;
  }

  .page_li img,
  .page_li_ img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .page_li span,
  .page_li_ span {
    float: left;
    display: inline-block;
    width: 167px;
    text-align: right;
    margin-right: 15px;
    line-height: 46px;
    color: #999999;
  }
  .textarea {
    width: 302px;
    outline: none;
    font-size: 14px;
    padding-top: 10px;
  }
  .explain{
    width: 400px !important;
    float: right !important;
    text-align: left !important;
  }
  .input {
    width: 302px;
    height: 46px;
    background-color: #ffffff;
    border: solid 1px #cfcfcf;
    padding-left: 25px;
    outline: none;
    font-size: 14px;
    // float:left;
  }

  .span_tel {
    font-size: 18px;
    line-height: 46px;
  }

  .btn_update_tel:hover {
    background-color: #ee2e2e;
    color: white;
  }

  .btn_update_tel {
    width: 84px;
    height: 26px;
    border-radius: 13px;
    background-color: white;
    color: #ee2e2e;
    border: solid 1px #ef2d2e;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;
  }

  .radio {
    display: inline-block;
    width: 146px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    margin-right: 10px;
  }

  .checked {
    background-color: #ffeee8;
    border: solid 1px #ee2e2e;
  }

  .noChecked {
    background-color: #ffffff;
    border: solid 1px #cfcfcf;
  }

  .div_reigsit ul li select {
    width: 93px;
    height: 46px;
    background-color: #ffffff;
    border: solid 1px #cfcfcf;
    margin-right: 12px;
    text-align: center;
    outline: none;
  }

  .div_reigsit ul li select option {
    text-align: center;
  }

  .dialogCommon {
    position: absolute;
    background: #ffffff;
    padding-bottom: 20px;
    width: 401px;
    z-index: 1;
    box-shadow: 2px 2px 32px 0px rgba(193, 193, 193, 0.72);
  }

  .chooseCommon {
    position: absolute;
    width: 401px;
    background: #ffffff;
    z-index: 1;
    border: 2px solid #ccc;
    margin-top: -6px;
    margin-left: 8px;
    box-shadow: 2px 2px 32px 0px rgba(193, 193, 193, 0.72);
  }

  .chooseMajor {
    top: 42.5%;
    margin-top: -8px;
    left: 20%;
  }

  /deep/ .el-dialog {
    width: 40%;
    // top: 23%;
  }

  /deep/ .el-dialog__wrapper {
  }

  .chooseCity {
    top: 47%;
    left: 20%;
    margin-top: -8px;
    padding-bottom: 20px;
  }

  .triangle_border_up {
    width: 0;
    height: 0;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #333; /*透明 透明  灰*/
    margin: 40px auto;
    position: relative;
  }

  .dialogCommonP {
    height: 36px;
    line-height: 36px;
    text-align: left;
    color: #444;
    font-size: 16px;
  }

  .dialogCommonImg {
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: -32px;
    width: 32px;
    height: 32px;
  }

  .chooseCityLi {
    margin-top: 20px;
    float: left;
  }

  .chooseP {
    padding-left: 12px;
    background: none repeat scroll 0 0 #f7f7f7;
  }

  .chooseSpan {
    font-size: 15px;
    color: #444444;
  }

  .chooseA {
    color: #ee2e2e;
    font-size: 15px;
    cursor: pointer;
  }

  .chooseDiv {
    margin: 0;
  }

  .chooseDiv ul {
    padding: 0px 24px 0px 24px;
  }

  .chooseDivLi {
    margin-right: 12px;
    text-align: center;
    height: 18px;
  }

  .chooseDivA {
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    color: #444444;
  }

  .chooseDivA:hover {
    color: #ee2e2e;
  }

  .view_yiying {
    position: absolute;
    top: 0;
    left: -235px;
    width: 1200px;
    height: 1200px;
    margin: 0;
    background-color: rgba(193, 193, 193, 0);
  }

  .btn_save_info {
    width: 90px;
    height: 28px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #ee2e2e;
    color: white;
    border: 1px solid #ee2e2e;
    margin: 48px 0px 0px -470px;
  }

  .zhul_diq_jiao {
    background: url(../../assets/image/zhul_lyBg.gif) repeat scroll -321px 0 rgba(0, 0, 0, 0);
    clear: both;
    height: 9px;
    left: 30px;
    overflow: hidden;
    position: absolute;
    top: -9px;
    width: 11px;
    z-index: 9999;
  }

  .dialogBindPhone {
    height: 310px;
    top: 28%;
    left: 20%;
    position: absolute;
    padding: 20px 25px;
    width: 460px;
  }

  .dialogBindPhoneTitle {
    font-weight: bold;
    font-size: 14px;
  }

  .dialogBindPhone li {
    height: 46px;
    margin-top: 8px;
  }

  .dialogBindPhone li:first-child {
    margin-top: 10px;
  }

  .dialogBindPhone li span {
    font-size: 12px;
    line-height: 46px;
    color: #999999;
  }

  .dialogBindCheckedCode {
    margin-top: 8px;
    //position: absolute;
    display: inline-block;
    width: 302px;
    height: 46px;
    margin-left: 11px;
    background-color: #ffffff;
    border: solid 1px #cfcfcf;
    padding-left: 25px;
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
    font-size: 12px;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
    font-size: 12px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
    font-size: 12px;
  }

  .dialogBindCheckedCode input {
    float: left;
    border: none;
    outline: none;
    line-height: 44px;
    margin-right: -50px;
  }

  .btn_bind_Phone {
    width: 100px;
    height: 28px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #ee2e2e;
    color: white;
    border: 1px solid #ee2e2e;
    margin-top: 25px;
  }

  .line {
    display: inline-block;
    height: 26px;
    width: 1px;
    margin-top: 10px;
    background-color: #999;
  }

  .getCode {
    display: inline-block;
    margin-left: 8px;
    position: absolute;
    line-height: 44px;
    background: white;
    font-size: 12px;
    color: #999;
    cursor: pointer;
  }

  .getCode:hover {
    color: #ee2e2e;
  }

  .chooseMajorLi {
    padding: 14px 0px;
    border-bottom: 1px solid #f3f3f3;
  }

  .chooseMajorLi:first-child {
    margin-top: 8px;
  }

  .chooseMajorLi:last-child {
    border: none;
  }

  .chooseMajorDl {
    float: left;
    text-align: left;
    margin-top: 6px;
  }

  .chooseMajorLi p {
    width: 200px;
    font-size: 14px;
  }

  .chooseMajorLi dl a {
    font-size: 12px;
  }

  .div_edi_user_icon {
    display: inline-block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }

  .div_icon {
    position: relative;
    left: 0px;
    display: inline-block;
    width: 200px;
    height: 64px;
    cursor: pointer;
  }

  .div_edi_user_icon {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 64px;
    width: 64px;
    line-height: 64px;
    margin: auto;
    display: none;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 51%;
    cursor: pointer;
  }

  .div_icon:hover .div_edi_user_icon {
    display: inline-block;
  }

  .iv_icon {
    float: left;
    height: 64px;
    width: 64px;
  }

  .el_select {
    width: 302px;
    height: 46px;
    border-radius: 0px;
  }

  //修改单个的选项的样式
  .item {
    color: #595959;
  }

  ::v-deep {
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #cfcfcf;
    }

    .el-select .el-input__inner:focus {
      border-color: #cfcfcf;
    }

    .el-input__inner {
      height: 46px;
      border: solid 1px #cfcfcf;
      border-radius: 0px;
      padding-left: 25px;
      font-size: 14px;

      &::placeholder {
        color: #999999;
        font-size: 10px;
      }
    }

    .email_tip {
      display: inline-block;
      margin-left: 20px;
      max-width: 400px;
      vertical-align: middle;
      color: red;
      font-size: 14px;
    }

    .avatar-uploader .el-upload {
      border: none;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .p_xh {
      display: inline-block;
      color: #ee2e2e;
    }
  }
  .select_calss {
    option {
      text-align: left !important;
    }
  }
  .select_calss1 {
    width: 302px;
    /deep/ .el-input__inner {
      padding-left: 30px !important;
    }
  }
  .dialogBindPhone_wx{
    height: 205px;
  }
  .addNew{
    text-align:left;
    padding-left:182px;
    margin-top:12px;
    color:#ee2e2e;
  }
</style>
<style>
.ant-tabs-nav .ant-tabs-tab-active{
  color: #ee2e2e;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #ee2e2e;
}
.ant-tabs-ink-bar {
		background-color: #ee2e2e;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #ee2e2e;
	}
	
	.ant-tabs-tab:hover {
		color: #ee2e2e;
	}
</style>
