<template>
  <div class="main_box">
    <!-- 技能标签 -->
    <div v-if="isLogger || (!isLogger && !tags_switch)" class="tags">
      <div class="item_title">
        <p>技能标签：</p>
        <div v-if="isLogger" class="item_right">
          <div v-if="outer_tags_list.length === 0" class="item_add" @click="tags_dialog = true">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="tags_switch"
              :parent-switch-event="switchTagsEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        title="添加技能标签"
        :visible.sync="tags_dialog"
        top="260px"
        width="520px"
      >
        <el-tag
          v-for="item in tags_list"
          :key="item.tag_name"
          closable
          :disable-transitions="false"
          @close="tag_dialogClose(item)"
        >
          {{ item }}
        </el-tag>
        <!-- <el-form :model="form"> -->
        <el-form>
          <el-input
            ref="saveTagInput"
            v-model="tag_inputValue"
            maxlength="10"
            show-word-limit
            class="tag_input"
            placeholder="请输入个性标签"
          >
          </el-input>
          <el-button type="primary" @click="addOneTagToList">添加标签</el-button>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSaveTags">保 存</el-button>
        </div>
      </el-dialog>
      <div v-if="outer_tags_list.length > 0" :class="isLogger ? 'tag_box' : 'tag_box_other'">
        <!-- 显示到页面 -->
        <div class="tag_lists">
          <el-tag
            v-for="(item) in outer_tags_list"
            :key="item.index"
            class="tag_class"
            :disable-transitions="false"
          >
            {{ item }}
          </el-tag>
        </div>
        <div class="tag_edit_delete">
          <div class="tag_edit_delete_l" @click="tags_dialog_show">
            <img src="@/assets/image/archive_edit_icon.png" alt="">
            <span>编辑</span>
          </div>
          <div class="tag_edit_delete_r" @click="deleteAllTags">
            <img src="@/assets/image/archive_delete_icon.png" alt="">
            <span>删除 </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 个人介绍 -->
    <div v-if="isLogger || (!isLogger && !introl_switch)" class="introl">
      <div class="item_title">
        <p>个人介绍：</p>
        <div v-if="isLogger" class="item_right">
          <div v-if="introl_info === '' || introl_info.length === 0" class="item_add" @click="introl_dialog = true">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="introl_switch"
              :parent-switch-event="switchIntrolEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <el-dialog
        class="social-el-dialog"
        title="添加个人介绍"
        :visible.sync="introl_dialog"
        top="260px"
        width="520px"
        :center="true"
      >
        <!-- <el-form :model="form"> -->
        <el-form label-width="90px">
          <span>*</span>
          <el-form-item label="个人介绍：">
            <el-input
              v-model="introl_inputValue"
              type="textarea"
              placeholder="请输入个人介绍"
              maxlength="100"
              show-word-limit
              :autosize="{ minRows: 5, maxRows: 7}"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSaveIntrol">保 存</el-button>
        </div>
      </el-dialog>
      <!-- 显示到页面 -->
      <div v-if="introl_info !== '' && introl_info.length > 0" :class="isLogger ? 'introl_box' : 'introl_box_other'">
        <div v-html="introl_info"></div>
        <div class="introl_edit_delete">
          <div class="introl_edit_delete_l" @click="introl_dialog = true">
            <img src="@/assets/image/archive_edit_icon.png" alt="">
            <span>编辑</span>
          </div>
          <div class="introl_edit_delete_r" @click="deleteIntrol_info">
            <img src="@/assets/image/archive_delete_icon.png" alt="">
            <span>删除 </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 教育经历 -->
    <div v-if="isLogger || (!isLogger && !edu_switch)" class="educations">
      <div class="item_title">
        <p>教育经历：</p>
        <div v-if="isLogger" class="item_right">
          <!-- v-if="edu_datas.length === 0" -->
          <div  class="item_add" @click="showEducationDialog()">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="edu_switch"
              :parent-switch-event="switchEduEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        class="edu_dialog_class"
        title="添加教育经历"
        :visible.sync="edu_dialog"
        top="260px"
        width="520px"
        :center="true"
        @close="eduCloseAction"
      >
        <el-form ref="eduRef" :model="edu_model" :rules="edu_rules" label-width="100px">
          <el-form-item label="学校名称" prop="school_name">
            <el-input v-model="edu_model.school_name" placeholder="请输入学校名称"></el-input>
          </el-form-item>
          <el-form-item label="就读时间：" required>
            <el-col :span="11">
              <el-form-item prop="start_time">
                <el-date-picker v-model="edu_model.start_time" value-format="yyyy" type="year" placeholder="选择日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">至</el-col>
            <el-col :span="11">
              <el-form-item prop="leave_time">
                <el-date-picker v-model="edu_model.leave_time" value-format="yyyy" type="year" placeholder="选择日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="专业" prop="major">
            <el-input v-model="edu_model.major" placeholder="请输入专业"></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="degree">
            <el-select v-model="edu_model.degree" placeholder="请选择">
              <el-option label="博士后" :value="1"></el-option>
              <el-option label="博士" :value="2"></el-option>
              <el-option label="硕士" :value="3"></el-option>
              <el-option label="本科" :value="4"></el-option>
              <el-option label="大专" :value="5"></el-option>
              <el-option label="中职技校" :value="6"></el-option>
              <el-option label="高中" :value="7"></el-option>
              <!-- <el-option label="小学"   value="1"></el-option>
              <el-option label="初中"   value="2"></el-option>
              <el-option label="高中"   value="3"></el-option>
              <el-option label="统招本科" value="4"></el-option>
              <el-option label="研究生"   value="5"></el-option>
              <el-option label="博士"    value="6"></el-option>
              <el-option label="成人自考" value="7"></el-option>
              <el-option label="网络教育" value="8"></el-option>
              <el-option label="无学历"   value="9"></el-option> -->
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveForEduAction('eduRef')">保 存</el-button>
        </div>
      </el-dialog>
      <!-- 展示页面 -->
      <div v-if="edu_datas.length>0" class="edu_box">
        <div v-for="(item,index) in edu_datas" :key="item.index" :class="isLogger ? 'edu_lists' : 'edu_lists_other'">
          <div class="edu_item">
            <span class="span_time">{{ item.start_time }} - {{ item.leave_time }}</span>
            <span>{{ item.school_name }}</span>
            <span>{{ item.school_name | addStringLast }}</span>
            <span>{{ item.major }}</span>
            <span>{{ item.major | addStringLast }}</span>
            <span>{{ item.degree_name }}</span>
          </div>
          <div class="edu_edit_delete">
            <div class="edu_edit_delete_l" @click="showEducationDialog(item,index)">
              <img src="@/assets/image/archive_edit_icon.png" alt="">
              <span>编辑</span>
            </div>
            <div class="edu_edit_delete_r" @click="deleteOneEduInfo(item)">
              <img src="@/assets/image/archive_delete_icon.png" alt="">
              <span>删除 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 项目经历： -->
    <div v-if="isLogger || (!isLogger && !project_switch)" class="projects">
      <div class="item_title">
        <p>项目经历：</p>
        <div v-if="isLogger" class="item_right">
          <!-- v-if="project_datas.length == 0" -->
          <div class="item_add" @click="showProjectDialog(null)">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="project_switch"
              :parent-switch-event="switchProjectEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        class="pro_dialog_class"
        title="添加项目经历"
        :visible.sync="project_dialog"
        top="160px"
        width="600px"
        :center="true"
        @close="projectCloseAction"
      >
        <el-form ref="proRef" :model="project_model" :rules="project_rules" label-width="100px">
          <el-form-item label="项目名称：">
            <el-input v-model="project_model.item_name"></el-input>
          </el-form-item>
          <el-form-item label="项目类型：">
            <el-select v-model="project_model.project_type" placeholder="请选择">
              <el-option v-for="(item,index) in projectOptions" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目规模：">
            <el-col :span="11">
              <el-input v-model="project_model.item_extent" style="width: 195px;"></el-input>
            </el-col>
            <el-col :span="11">
              <el-select v-model="project_model.extent_unit" placeholder="㎡">
                <!-- 1111111111111 -->
                <el-option v-for="(item,index) in unit_options" :key="index" :label="item.label" :value="item.value"></el-option>
                <!-- <el-option label="㎡" :value="1"></el-option>
                <el-option label="公顷" :value="2"></el-option>
                <el-option label="公里" :value="3"></el-option>
                <el-option label="项" :value="4"></el-option>
                <el-option label="亩" :value="5"></el-option>
                <el-option label="米" :value="6"></el-option> -->
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="项目时间：">
            <el-col :span="11">
              <el-form-item>
                <el-date-picker v-model="project_model.start_time" value-format="yyyy" type="year" placeholder="选择日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">至</el-col>
            <el-col :span="11">
              <el-form-item>
                <el-date-picker v-model="project_model.end_time" value-format="yyyy" type="year" placeholder="选择日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="职位">
            <div class="cus_position">
              <span v-if="proSelectZhuanyeName.length===0" class="zhuanye_btn" @click="showProZhuanyeDialog">请选择专业</span>
              <span v-else class="zhuanye_btn_select" @click="showProZhuanyeDialog">{{ proSelectZhuanyeName }}</span>
              <div v-show="show_pro_pos_zy" class="selec_zy">
                <div class="select_zy_top">
                  <p class="zy_top_jiao"></p>
                  <p class="zy_top_title">感兴趣的专业</p>
                  <p class="zy_top_close" @click="closeProZhuanyeDialog">
                    <span class="jues_zy_close_x"></span>
                  </p>
                </div>
                <div class="cate_box_top">
                  <p>请务必选择最适合您的专业，我们将为您匹配不同的内容、人脉圈子以及专业活动等内容，使您获得最好的服务。</p>
                </div>
                <div class="cate_box_bg">
                  <div v-for="cate in proCateDatas" :key="cate.index" class="cate_box_list">
                    <p class="cate_title"><b>{{ cate.title }}</b></p>
                    <ul class="cate_ul">
                      <li v-for="sub_cate in cate.datas" :key="sub_cate.index" class="cate_li" @click="proSelectZhuanye(cate,sub_cate)">
                        {{ sub_cate.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- 选择角色 -->
              <span v-if="proJuseSelect" class="select_juese" @click="showProJueseDialog">{{ proSelectJueseName.length==0 ? '请选择角色' : proSelectJueseName }}</span>

              <!-- 输入角色 -->
              <input v-else v-model="proJuesInput" class="input_juese" placeholder="请输入您担任的角色" type="text" value="">

              <div v-show="show_pro_pos_jues" class="selec_jues">
                <div class="select_jues_top">
                  <p class="jues_top_jiao"></p>
                  <p class="jues_top_title">请选择角色</p>
                  <p class="jues_top_close" @click="closeProJueseDialog">
                    <span class="jues_top_close_x"></span>
                  </p>
                </div>
                <div class="cate_box_bg">
                  <div v-for="sub in proCreatJueseDatas" :key="sub.index" class="cate_box_list">
                    <p class="cate_title">{{ sub.title }}</p>
                    <div class="ul_div">
                      <ul class="cate_ul">
                        <li v-for="sub_cate in sub.datas" :key="sub_cate.index" class="cate_li" @click="proSelectJuese(sub,sub_cate)">
                          {{ sub_cate.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </el-form-item>
          <el-form-item label="工作成果：">
            <el-input
              v-model="project_model.desc"
              type="textarea"
              placeholder="请输入工作内容"
              :autosize="{ minRows: 5, maxRows: 7}"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目图片：">
            <el-upload
              action="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=project"
              :show-file-list="false"
              :on-success="proImgaeUploadSuccess"
              :on-error="proImgaeUploadError"
              :on-progress="proImgaeUploadProgress"
              :before-upload="beforeProjectImageUpload"
            >
              <img v-if="proUploadImageUrl !== ''" :src="proUploadImageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="upload_alert">
              <p>图片规格：</p>
              <p>封面图最大不能超过6M</p>
            </div>
          </el-form-item>
        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveForProjectAction('proRef',project_model)">保 存</el-button>
        </div>
      </el-dialog>
      <!-- 展示页面 -->
      <div v-if="project_datas.length>0" class="pro_box">
        <div v-for="item in project_datas" :key="item.index" :class="isLogger ? 'pro_lists' : 'pro_lists_other'">
          <div class="pro_item">
            <div v-if="item.item_img && item.item_img.length>0" class="thumb">
              <img :src="item.item_img" alt="">
            </div>
            <div class="pro_info">
              <p class="pro_title">{{ item.item_name }}
                <span style="margin:0 10px"></span>
                {{ item.position }}</p>
              <span v-if="item.start_time">工作时间：{{ item.start_time }} - {{ item.end_time }} | </span>
              <span v-if="item.item_extent">项目规模：{{ item.item_extent }}{{ getProType(item.extent_unit,unit_options) }} | </span>
              <span v-if="item.project_type">项目类型：{{ getProType(item.project_type,projectOptions) }}</span>
              <p v-if="item.desc" class="pro_detail">工作成果：{{ item.desc }}</p>
            </div>
          </div>
          <div class="pro_edit_delete">
            <div class="pro_edit_delete_l" @click="showProjectDialog(item)">
              <img src="@/assets/image/archive_edit_icon.png" alt="">
              <span>编辑</span>
            </div>
            <div class="pro_edit_delete_r" @click="deleteOneProject(item)">
              <img src="@/assets/image/archive_delete_icon.png" alt="">
              <span>删除 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 工作经历： -->
    <div v-if="isLogger || (!isLogger && !work_switch)" class="works">
      <div class="item_title">
        <p>工作经历：</p>
        <div v-if="isLogger" class="item_right">
          <!--  v-if="carrers.length === 0" -->
          <div class="item_add" @click="work_edit_info(null)">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="work_switch"
              :parent-switch-event="switchWorkEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        class="work-el-dialog"
        title="添加工作经历"
        :visible.sync="work_dialog"
        top="260px"
        width="520px"
        :center="true"
        @close="handleClose"
      >
        <!-- <el-form :model="form"> -->
        <el-form ref="workRef" :model="work_form" :rules="work_rules" label-width="100px">
          <el-form-item label="单位名称：" prop="company_name">
            <el-input v-model="work_form.company_name">{{ work_form.company_name }}</el-input>
          </el-form-item>
          <el-form-item label="工作时间：" required>
            <el-col :span="11">
              <el-form-item prop="start_time">
                <el-date-picker v-model="work_form.start_time" value-format="yyyy" type="year" placeholder="选择日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">至</el-col>
            <el-col :span="11">
              <el-form-item prop="end_time">
                <el-date-picker v-model="work_form.end_time" value-format="yyyy" type="year" placeholder="选择日期" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="部门职位：" prop="position">
            <el-input v-model="work_form.position"></el-input>
          </el-form-item>

          <el-form-item label="工作描述：" prop="desc">
            <el-input
              v-model="work_form.desc"
              type="textarea"
              placeholder="请输入工作内容"
              :autosize="{ minRows: 5, maxRows: 7}"
            >
            </el-input>
          </el-form-item>

        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSaveWorkItem('workRef',work_form)">保 存</el-button>
        </div>
      </el-dialog>

      <div v-show="carrers.length>0" class="work_list">
        <div v-for="(item,index) in carrers" :key="index" :class="isLogger ? 'work_box' : 'work_box_other'">
          <!-- 显示到页面 -->
          <!-- <p class="social_info_p">
            {{social_info}}
          </p> -->
          <div class="work_edit_delete">
            <div class="work_edit_delete_l" @click="work_edit_info(item,index)">
              <img src="@/assets/image/archive_edit_icon.png" alt="">
              <span>编辑</span>
            </div>
            <div class="work_edit_delete_r" @click="deleteWork_info(item)">
              <img src="@/assets/image/archive_delete_icon.png" alt="">
              <span>删除 </span>
            </div>
          </div>

          <div class="work_info_top">
            <span>{{ item.start_time }}--{{ item.end_time==='0'?"至今":item.end_time }}</span>
            <span class="work_info_line"></span>
            <span>{{ item.company_name }}&nbsp;/{{ item.position }}</span>
          </div>
          <div class="work_info_down">
            <span>工作描述：</span>
            <span class="work_info_line"></span>
            <span v-html="item.desc">{{ item.desc }}</span>
          </div>

        </div>
      </div>

    </div>
    <!-- 项目经验： -->
    <!-- <div class="experience">
      <div class="item_title">
        <p>项目经验：</p>
        <div class="item_right">
          <div v-if="experience_add" class="item_add">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :isOnValue = "experience_switch"
              :parentSwitchEvent="switchExperienceEvent"
              >
            </zlSwitch>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 相关论著： -->
    <div v-if="isLogger || (!isLogger && !social_switch)" class="social">
      <div class="item_title">
        <p>主要论著：</p>
        <div v-if="isLogger" class="item_right">
          <div v-if="social_info === ''||social_info.length===0" class="item_add" @click="social_dialog = true">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="social_switch"
              :parent-switch-event="switchSocialEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        class="social-el-dialog"
        title="添加主要论著"
        :visible.sync="social_dialog"
        top="260px"
        width="520px"
        :center="true"
      >
        <!-- <el-form :model="form"> -->
        <el-form label-width="90px">
          <span>*</span>
          <el-form-item label="主要论著：">
            <el-input
              v-model="social_inputValue"
              type="textarea"
              placeholder="请输入论著内容"
              :autosize="{ minRows: 5, maxRows: 7}"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSaveSocial">保 存</el-button>
        </div>
      </el-dialog>

      <div v-if="social_info && (social_info !== '' && social_info.length > 0)" :class="isLogger ? 'social_box' : 'social_box_other'">
        <!-- 显示到页面 -->
        <div class="social_info_div">
          <div class="social_info_p" v-html="social_info">
            
          </div>
        </div>
        <div class="social_edit_delete">
          <div class="social_edit_delete_l" @click="social_dialog = true">
            <img src="@/assets/image/archive_edit_icon.png" alt="">
            <span>编辑</span>
          </div>
          <div class="social_edit_delete_r" @click="deleteSocial_info">
            <img src="@/assets/image/archive_delete_icon.png" alt="">
            <span>删除 </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 个人成就： -->
    <div v-if="isLogger || (!isLogger && !chengjiu_switch)" class="chengjiu">
      <div class="item_title">
        <p>个人成就：</p>
        <div v-if="isLogger" class="item_right">
          <div v-if="chengjiu_info === '' || chengjiu_info.length===0" class="item_add" @click="chengjiu_dialog = true">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="chengjiu_switch"
              :parent-switch-event="switchChengjiuEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        class="social-el-dialog"
        title="添加个人成就"
        :visible.sync="chengjiu_dialog"
        top="260px"
        width="520px"
        :center="true"
      >
        <!-- <el-form :model="form"> -->
        <el-form label-width="90px">
          <span>*</span>
          <el-form-item label="个人成就：">
            <el-input
              v-model="chengjiu_inputValue"
              type="textarea"
              placeholder="请输入成就内容"
              :autosize="{ minRows: 5, maxRows: 7}"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSaveChengjiu">保 存</el-button>
        </div>
      </el-dialog>

      <div v-if="chengjiu_info && (chengjiu_info !== '' && chengjiu_info.length > 0)" :class="isLogger ? 'social_box' : 'social_box_other'">
        <!-- 显示到页面 -->
        <div class="social_info_div">
          <div class="social_info_p" v-html="chengjiu_info">
          </div>
        </div>
        <div class="social_edit_delete">
          <div class="social_edit_delete_l" @click="chengjiu_dialog = true">
            <img src="@/assets/image/archive_edit_icon.png" alt="">
            <span>编辑</span>
          </div>
          <div class="social_edit_delete_r" @click="deleteChengjiu_info">
            <img src="@/assets/image/archive_delete_icon.png" alt="">
            <span>删除 </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 社会职务： -->
    <div v-if="isLogger || (!isLogger && !position_switch)" class="position">
      <div class="item_title">
        <p>社会职务：</p>
        <div v-if="isLogger" class="item_right">
          <div v-if="position_info === '' || position_info.length === 0" class="item_add" @click="position_dialog = true">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="position_switch"
              :parent-switch-event="switchPositionEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>

      <!-- 展示弹框 -->
      <el-dialog
        class="social-el-dialog"
        title="添加社会职务"
        :visible.sync="position_dialog"
        top="260px"
        width="520px"
        :center="true"
      >
        <!-- <el-form :model="form"> -->
        <el-form label-width="90px">
          <span>*</span>
          <el-form-item label="社会职务：">
            <el-input
              v-model="position_inputValue"
              type="textarea"
              placeholder="请输入职务内容"
              :autosize="{ minRows: 5, maxRows: 7}"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <!-- 弹窗尾部 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSavePosition">保 存</el-button>
        </div>
      </el-dialog>

      <div v-if="position_info && (position_info !== '' && position_info.length > 0)" :class="isLogger ? 'social_box' : 'social_box_other'">
        <!-- 显示到页面 -->
        <div class="social_info_div">
          <div class="social_info_p" v-html="position_info"></div>
        </div>
        <div class="social_edit_delete">
          <div class="social_edit_delete_l" @click="position_dialog = true">
            <img src="@/assets/image/archive_edit_icon.png" alt="">
            <span>编辑</span>
          </div>
          <div class="social_edit_delete_r" @click="deletePosition_info">
            <img src="@/assets/image/archive_delete_icon.png" alt="">
            <span>删除 </span>
          </div>
        </div>
      </div>

    </div>
    <!-- 职业资格证书： -->
    <div v-if="isLogger || (!isLogger && !certify_switch)" class="certify">
      <div class="item_title">
        <p>职业资格证书：</p>
        <div v-if="isLogger" class="item_right">
          <div v-if="certify_info === '' || certify_info.length === 0" class="item_add" @click="certify_dialog = true">
            <img src="@/assets/image/archive_item_add.png" alt="">
            <span>添加</span>
          </div>
          <div class="switch">
            <zlSwitch
              :is-on-value="certify_switch"
              :parent-switch-event="switchCertifyEvent"
            >
            </zlSwitch>
          </div>
        </div>
      </div>
      <!-- 展示弹框 -->
      <el-dialog
        title="编辑职业资格证书"
        :visible.sync="certify_dialog"
        top="260px"
        width="400px"
      >
        <el-tag
          v-for="item in certify_info_array"
          :key="item"
          closable
          :disable-transitions="false"
          class="certify_tag"
          @close="certify_dialogClose(item)"
        >
          {{ item }}
        </el-tag>
        <!-- <el-form :model="form"> -->
        <el-form>
          <el-input
            ref="saveCertifyInput"
            v-model="certify_inputValue"
            width="400px"
            class="certify_input"
            placeholder="请输入职业资格证书"
            @blur="inputCertifyConfirm"
          >
          </el-input>
          <el-button type="primary" @click="addOneCertifyToList">添加证书</el-button>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestForSaveCertify">保 存</el-button>
        </div>
      </el-dialog>
      <div v-if="certify_info !== '' && certify_info.length > 0" :class="isLogger ? 'certify_box' : 'certify_box_other'">
        <!-- 显示到页面 -->
        <div class="social_info_div">
          <p class="social_info_p">
            {{ certify_info }}
          </p>
        </div>
        <div class="certify_edit_delete">
          <div class="certify_edit_delete_l" @click="certify_dialog = true">
            <img src="@/assets/image/archive_edit_icon.png" alt="">
            <span>编辑</span>
          </div>
          <div class="certify_edit_delete_r" @click="deleteCertify_info">
            <img src="@/assets/image/archive_delete_icon.png" alt="">
            <span>删除 </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myArchiveInfo } from '@/api/center'
import zlSwitch from '@/components/switch/zlSwitch'
import { setPrivacy, deleteAllTags, editOrAddTags, addOrUpdateArchiveJob, deleteArchiveJob, archiveItem, deleteArchiveEducation,
  addArchiveEducation, editArchiveEducation, addOrUpdateArchiveProject, deleteArchiveProject } from '@/api/archive'
export default {
  filters: {
    zeroToZhijin(s) {
      if (s === '0' || s === 0) {
        return '至今'
      }
      return s
    },
    addStringLast(s) {
      if (s !== undefined && s.length > 0) {
        return '/'
      }
      return ''
    }
  },
  components: {
    zlSwitch
  },
  data() {
    return {
      token:this.$route.query.token,
      isLogger: true,
      toUid: this.$route.query.u ? this.$route.query.u : '',
      post_num: 0,
      // 技能标签
      tags_dialog: false,
      tags_add: true,
      tags_switch: true,
      outer_tags_list: [],//外层显示的标签
      tags_list: [],
      tag_inputValue: '',
      // 个人介绍
      introl_add: true,
      introl_dialog: false,
      introl_switch: true,
      introl_info: '',
      introl_inputValue: '',
      // 教育经历
      edu_degree_data: {
        1: '博士后',
        2: '博士',
        3: '硕士',
        4: '本科',
        5: '大专',
        6: '中职技校',
        7: '高中'
      },
      edu_add: true,
      edu_switch: true,
      edu_dialog: false,
      edu_model: {},
      edu_datas: [],
      edu_rules: {
        school_name: [
          { required: true, message: '请输入学校名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        start_time: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        leave_time: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        major: [
          { required: true, message: '请添加专业', trigger: 'blur' }
        ],
        degree: [
          { required: true, message: '请选择学历', trigger: 'blur' }
        ]
      },
      // 项目经历
      // 单位
      unit_options:[{
        label:'㎡',value:1
        },{
          label:'公顷',value:2
        },{
          label:'公里',value:3
        },{
          label:'项',value:4
        },{
          label:'亩',value:5
        },{
          label:'米',value:6
      }],
      project_add: true,
      project_switch: true,
      project_datas: [],
      project_dialog: false,
      proUploadImageUrl: '',
      proSelectZhuanyeName: '',
      proSelectZhuanyeID: '',
      proSelectJueseName: '',
      proSelectJueseID: '',
      proCreatJueseDatas: [],
      projectOptions:[{
        label:'城市综合体',value:'1'
        },{
          label:'综合楼建筑',value:'2'
        },{
          label:'居住建筑',value:'3'
        },{
          label:'办公建筑',value:'4'
        },{
          label:'其它',value:'999'
        }],
      proCateDatas: [
        {
          title: '艺术设计类',
          datas: [
            {
              id: '1',
              name: '建筑设计',
              // 1建筑设计子分类
              sub: [
                {
                  id: '21',
                  title: '设计类',
                  datas: [
                    {
                      id: '31',
                      name: '设计总监'
                    },
                    {
                      id: '32',
                      name: '主创设计师'
                    },
                    {
                      id: '33',
                      name: '设计师'
                    }
                  ]
                },
                {
                  id: '22',
                  title: '工程类',
                  datas: [
                    {
                      id: '34',
                      name: '项目负责人'
                    },
                    {
                      id: '35',
                      name: '专业负责人'
                    },
                    {
                      id: '36',
                      name: '设计人'
                    }
                  ]
                },
                {
                  id: '23',
                  title: '经济类',
                  datas: [
                    {
                      id: '37',
                      name: '项目负责人'
                    },
                    {
                      id: '38',
                      name: '造价师'
                    },
                    {
                      id: '39',
                      name: '造价员'
                    }
                  ]
                },
                {
                  id: '24',
                  title: '其他',
                  datas: []
                }
              ]
            },
            {
              id: '3',
              name: '园林景观',
              sub: []
            },
            {
              id: '2',
              name: '室内景观',
              sub: []
            },
            {
              id: '15',
              name: '照明设计',
              sub: []
            },
            {
              id: '14',
              name: '渲染表现',
              sub: []
            }
          ]
        },
        {
          title: '工程技术类',
          datas: [
            {
              id: '16',
              name: '结构设计',
              sub: []
            },
            {
              id: '5',
              name: '建筑施工',
              // 2 建筑施工子分类
              sub: [
                {
                  id: '25',
                  title: '设计类',
                  datas: [
                    {
                      id: '40',
                      name: '施工员'
                    },
                    {
                      id: '41',
                      name: '项目总工'
                    },
                    {
                      id: '42',
                      name: '项目经理'
                    }
                  ]
                }
              ]
            },
            {
              id: '9',
              name: '路桥市政',
              sub: []
            },
            {
              id: '17',
              name: '岩土工程',
              sub: []
            },
            {
              id: '13',
              name: '水利水电',
              sub: []
            }
          ]
        },
        {
          title: '机电设备类',
          datas: [
            {
              id: '8',
              name: '电气工程',
              sub: []
            },
            {
              id: '7',
              name: '给排水',
              sub: []
            },
            {
              id: '6',
              name: '暖通空调',
              sub: []
            }
          ]
        },
        {
          title: '造价与管理类',
          datas: [
            {
              id: '12',
              name: '房地产',
              sub: []
            },
            {
              id: '4',
              name: '工程造价',
              sub: []
            },
            {
              id: '11',
              name: '工程监理',
              sub: [
                {
                  id: '26',
                  title: '监理类',
                  datas: [
                    {
                      id: '43',
                      name: '监理员'
                    },
                    {
                      id: '44',
                      name: '总监代表'
                    },
                    {
                      id: '45',
                      name: '项目总监'
                    }
                  ]
                }
              ]
            },
            {
              id: '10',
              name: '项目管理',
              sub: []
            }
          ]
        },
        {
          title: '其他类别',
          datas: [
            {
              id: '18',
              name: '建筑材料',
              sub: []
            },
            {
              id: '21',
              name: 'BIM',
              sub: []
            },
            {
              id: '20',
              name: '其他专业',
              sub: []
            }
          ]
        }
      ],
      // true 选择
      proJuseSelect: false,
      project_model: {},
      project_rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      },
      // 自定义专业选择弹框
      show_pro_pos_zy: false,
      show_pro_pos_jues: false,
      proJuesInput: '',

      // 工作经历
      work_add: true,
      work_switch: true,
      work_dialog: false,
      carrers: [],
      work_form: {},
      // 工作经历表单提交 必填提示
      work_rules: {
        company_name: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        start_time: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        end_time: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请添加职位', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请添加描述', trigger: 'blur' }
        ]
      },
      // 项目经验
      experience_add: true,
      experience_switch: true,
      // 论著
      social_add: true,
      social_switch: true,
      social_dialog: false,
      social_info: '',
      social_inputValue: '',
      // 个人成就
      chengjiu_add: true,
      chengjiu_switch: true,
      chengjiu_dialog: false,
      chengjiu_info: '',
      chengjiu_inputValue: '',
      // 社会职务
      position_add: true,
      position_switch: true,
      position_dialog: false,
      position_info: '',
      position_inputValue: '',
      // 职业资格证书
      certify_add: true,
      certify_switch: true,
      certify_dialog: false,
      certify_info: '',
      certify_info_array: [],
      certify_inputValue: ''
    }
  },
  created() {
    this.getUerArchiveInfo()
  },
  methods: {
    // 项目类型根据value查找label
    getProType(value,proType) {
      for (let i = 0; i < proType.length; i++) {
        const item = proType[i]
        if (item.value == value) {
          return item.label
        }
      }
    },
    // 用户档案信息
    getUerArchiveInfo() {
      const param = { uid: this.toUid ,token: this.token }
      myArchiveInfo(param).then(res => {
        const result = res.result
        // 1本人，0非本人
        this.isLogger = true
        // privacy 0 隐藏
        const privacy = result.privacy
        // 标签
        this.tags_switch = Boolean(privacy.tags)
        console.log(this.tags_switch)
        const tags = result.tags
        if (tags && tags !== undefined && tags !== null && tags.length > 0) {
          this.outer_tags_list = []
          tags.forEach(tag => {
            this.outer_tags_list.push(tag.tag_name)
          })
        }else{
          this.outer_tags_list = []
        }
        // 介绍
        this.introl_switch = Boolean(privacy.intro)
        this.introl_info = result.intro.replace(/\n/g,"<br>")
        this.introl_inputValue = this.introl_info.replace(/<br>/g,"\n")
        // 教育经历
        const edu = result.educations
        if (edu && edu !== undefined && edu !== null && edu.length > 0) {
          this.edu_datas = edu
        }
        this.edu_switch = Boolean(privacy.educations)
        // 项目经历
        const project = result.projects
        if (project && project !== undefined && project !== null && project.length > 0) {
          this.project_datas = project
        }
        this.project_switch = Boolean(privacy.projects)
        // 工作经历
        this.work_switch = Boolean(privacy.carrers)
        const work = res.result.carrers
        if (work && work !== undefined && work !== null && work.length > 0) {
          this.carrers = work
        }
        // 主要论著
        this.social_switch = Boolean(privacy.social_work)
        this.social_info = result.social_work.replace(/\n/g,"<br>")
        this.social_inputValue = this.social_info.replace(/<br>/g,"\n")
        // 个人成就
        this.chengjiu_switch = Boolean(privacy.achievement)
        this.chengjiu_info = result.achievement.replace(/\n/g,"<br>")
        this.chengjiu_inputValue = this.chengjiu_info.replace(/<br>/g,"\n")
        // 社会职务
        this.position_switch = Boolean(privacy.socialduties)
        this.position_info = result.socialduties.replace(/\n/g,"<br>")
        this.position_inputValue = this.position_info.replace(/<br>/g,"\n")

        // 职业资格战术
        this.certify_switch = Boolean(privacy.certificate)
        const certy = result.certificate
        if (certy && certy.length > 0 && certy !== null && certy !== '') {
          this.certify_info = result.certificate
          this.certify_info_array = this.certify_info.split(',')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 公开、隐藏 技能标签
    switchTagsEvent() {
      const v = Number(!this.tags_switch)
      const param = { name: 'tags', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.tags_switch = !this.tags_switch
          console.log('修改 技能标签 状态 ' + this.tags_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 删除一个标签，弹框
    tag_dialogClose(tag) {
      this.tags_list.splice(this.tags_list.indexOf(tag), 1)
    },
    // 添加一个标签
    inputTagConfirm() {
      const input = this.tag_inputValue
      if (input) {
        this.tags_list.push(input)
      }
      this.tag_inputValue = ''
    },
    // 添加标签之后输入框获取光标
    addOneTagToList() {
      this.inputTagConfirm()
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 编辑标签
    tags_dialog_show(){
      this.tags_dialog = true
      this.tags_list = JSON.parse(JSON.stringify(this.outer_tags_list))
    },
    deleteAllTags() {
      // 删除所有标签
      const p = {token: this.token}
      deleteAllTags(p).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success(res.result)
          this.getUerArchiveInfo()
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    requestForSaveTags() {
      // 判断标签数是否大于4
      if (this.tags_list.length > 4) {
        this.$message.error('最多只能添加4个标签')
        return
      }
      // 操作之后，保存 请求接口
      this.tags_dialog = false
      const tagStr = this.tags_list.join(',')
      const params = { tags: tagStr ,token: this.token}
      editOrAddTags(params).then(res => {
        console.log(res)
        if(res.errNo==0){
          this.getUerArchiveInfo()
          this.$message.success(res.result)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 公开、隐藏 个人介绍
    switchIntrolEvent() {
      const v = Number(!this.introl_switch)
      const param = { name: 'intro', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.introl_switch = !this.introl_switch
          console.log('修改 个人介绍 状态 ' + this.introl_switch)
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 删除 个人介绍
    deleteIntrol_info() {
      const introl = ''
      const params = { intro: introl ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success(res.msg)
          this.introl_info = ''
          this.introl_inputValue = ''
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    // 确定输入
    inputIntrolConfirm() {
      const input = this.introl_inputValue
      if (input) {
        this.introl_info = input
      }
      // this.tag_inputValue = '';
    },
    // 保存 请求
    requestForSaveIntrol() {
      this.introl_dialog = false
      const introl = this.introl_inputValue
      const params = { intro: introl ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          // this.introl_info = introl
          // this.introl_inputValue = this.introl_info
          this.getUerArchiveInfo()
          this.$message.success('保存成功')
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err => {
      })
    },
    // 公开、隐藏 教育经历
    switchEduEvent() {
      const v = Number(!this.edu_switch)
      const param = { name: 'educations', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.edu_switch = !this.edu_switch
          console.log('修改 教育经历 状态 ' + this.edu_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 展示edu的弹框
    showEducationDialog(edu, index) {
      console.log(edu, 'edu')
      if (edu !== undefined && index !== undefined) {
        edu.arr_index = index
        edu.start_time = String(edu.start_time)
        edu.leave_time = String(edu.leave_time)
        this.edu_model = JSON.parse(JSON.stringify(edu))
      } else {
        this.edu_model = {}
      }
      this.edu_dialog = true
    },
    // 关闭edu展示
    eduCloseAction() {
      // 弹框消失，清空表单提示
      if (this.edu_model.is_request !== undefined && this.edu_model.is_request === true) {
        this.$refs.eduRef.clearValidate()
      } else {
        this.$refs.eduRef.resetFields()
      }
    },
    // 删除一个教育经历
    deleteOneEduInfo(item) {
      const params = { id: item.id ,token: this.token}
      deleteArchiveEducation(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success(res.result)
          this.edu_datas.splice(this.edu_datas.indexOf(item), 1)
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 保存教育经历 确认输入
    saveForEduAction(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          // 验证通过 调接口
          this.edu_model.is_request = true
          const idStr = this.edu_model.id +''
          console.log(idStr)
          const start_time = Number(this.edu_model.start_time)
          const leave_time = Number(this.edu_model.leave_time)
          if (leave_time < start_time) {
            return this.$message.error('结束时间不能小于开始时间')
          }
          if (idStr != 'undefined' && idStr.length > 0) {
            console.log('编辑教育经历' + idStr)
            this.requestForEditEducation(this.edu_model)
          } else {
            console.log('新加教育经历')
            this.requestForaddEducation(this.edu_model)
          }
        } else {
          this.edu_model.is_request = false
          return false
        }
      })
    },
    // 保存教育经历请求
    requestForaddEducation(edu) {
      const school_name = edu.school_name
      const start_time = edu.start_time
      const leave_time = edu.leave_time
      const major = edu.major
      const degree = edu.degree
      const degree_name = this.edu_degree_data[degree]
      edu.degree_name = degree_name
      const params = {
        school_name: school_name,
        start_time: start_time,
        leave_time: leave_time,
        major: major,
        degree: degree,
        token: this.token
      }
      console.log(params)
      console.log(degree_name)
      addArchiveEducation(params).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          this.getUerArchiveInfo()
          this.$message.success('保存成功')
          this.edu_dialog = false
          const addId = String(res.result)
          console.log('添加教育经历成功' + addId)
          this.edu_model.id = addId
          console.log(this.edu_model.id)
          this.edu_datas.push(this.edu_model)
        } else {
          const msg = res.msg
          console.log(msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 编辑教育经历
    requestForEditEducation(edu) {
      const idStr = edu.id
      const school_name = edu.school_name
      const start_time = edu.start_time
      const leave_time = edu.leave_time
      const major = edu.major
      const degree = edu.degree
      const degree_name = this.edu_degree_data[degree]
      edu.degree_name = degree_name
      const params = {
        school_name: school_name,
        start_time: start_time,
        leave_time: leave_time,
        major: major,
        degree: degree,
        id: idStr,
        token: this.token
      }
      // console.log(idStr)
      // console.log(school_name)
      // console.log(start_time)
      // console.log(leave_time)
      // console.log(major)
      // console.log(degree)
      // console.log(degree_name)
      // console.log(edu.degree_name)
      // console.log('编辑 ' + params);
      editArchiveEducation(params).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          console.log('编辑成功')
          this.edu_dialog = false
          this.edu_datas.splice(this.edu_model.arr_index, 1, edu)
        } else {
          const msg = res.msg
          console.log(msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },

    // 公开、隐藏 项目经历
    switchProjectEvent() {
      const v = Number(!this.project_switch)
      const param = { name: 'projects', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.project_switch = !this.project_switch
          console.log('修改 项目经历 状态 ' + this.project_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 项目图片
    beforeProjectImageUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 6
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 6MB!')
      }
      return isJPG && isLt2M
    },
    // 项目图片上传成功
    proImgaeUploadSuccess(res, file) {
      console.log('上传成功')
      this.proUploadImageUrl = res.url
      console.log(this.proUploadImageUrl)
    },
    // 项目图片上传失败
    proImgaeUploadError(err, file) {
      console.log(err)
      console.log(file)
    },
    // 项目图片上传进度
    proImgaeUploadProgress(eve, file) {
      console.log(eve)
      console.log(file)
    },
    // 二级弹框
    showProZhuanyeDialog() {
      this.show_pro_pos_zy = true
    },
    closeProZhuanyeDialog() {
      this.show_pro_pos_zy = false
    },
    // 二级弹框 选择专业
    proSelectZhuanye(cate, sub_cate) {
      console.log(cate.title)
      console.log(sub_cate.name)
      const sub = sub_cate.sub
      console.log(sub)

      // 记录专业 选择的数据，然后关闭
      this.proSelectZhuanyeName = sub_cate.name
      this.proSelectZhuanyeID = sub_cate.id
      console.log('专业id' + sub_cate.id)

      this.proCreatJueseDatas = sub
      this.proSelectJueseName = ''
      this.proSelectJueseID = ''
      this.proJuesInput = ''

      this.closeProZhuanyeDialog()

      // 判断 展开角色 二级弹框
      if (sub.length > 0) {
        // 展示右侧 角色 二级弹框
        this.proJuseSelect = true
        this.showProJueseDialog()
      } else {
        this.proJuseSelect = false
      }
    },
    // 二级角色弹框
    showProJueseDialog() {
      this.show_pro_pos_jues = true
    },
    // 二级角色弹框关闭
    closeProJueseDialog() {
      this.show_pro_pos_jues = false
    },
    // 二级弹框 选择角色
    proSelectJuese(sub, sub_cate) {
      console.log('角色分类', sub.title)
      console.log('角色选择--', sub_cate.name)
      console.log('角色id', sub_cate.id)

      this.proSelectJueseName = sub_cate.name
      this.proSelectJueseID = sub_cate.id

      this.closeProJueseDialog()
    },

    // 一级弹框 展示 pro 弹框
    showProjectDialog(pro) {
      this.project_dialog = true
      console.log(pro, 'pro')
      if (pro) {
        pro.start_time = pro.start_time==0?'':String(pro.start_time)
        pro.end_time = pro.end_time==0?'':String(pro.end_time)
        this.project_model = JSON.parse(JSON.stringify(pro))
        this.proUploadImageUrl = pro.item_img
        this.proSelectZhuanyeName = pro.position
        this.proSelectJueseName = pro.major

        this.proJuesInput = pro.major
        this.proJuseSelect = false
        for(let i = 0;i<this.proCateDatas.length;i++){
          for(let j=0;j<this.proCateDatas[i].datas.length;j++){
            if(this.proCateDatas[i].datas[j].name==pro.position&&this.proCateDatas[i].datas[j].sub.length>0){
              this.proJuseSelect = true
              return
            }
          }
        }
        
      }else{
        this.project_model = {}
        this.proUploadImageUrl = ''
      }
      // this.project_dialog = true
      // proJuseSelect
      
    },
    // 一级弹框 关闭 pro 弹框
    projectCloseAction() {
      this.show_pro_pos_zy = false
      this.$refs.proRef.clearValidate()
      this.proSelectZhuanyeName = ''
      this.proSelectZhuanyeID = ''
      this.proSelectJueseName = ''
      this.proSelectJueseID = ''
      this.proJuesInput = ''
    },
    // 删除一个 pro
    deleteOneProject(pro) {
      const idStr = pro.id
      const param = { id: idStr ,token: this.token}
      deleteArchiveProject(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.project_datas.splice(this.project_datas.indexOf(pro), 1)
          console.log('删除成功')
          this.$message.success('删除成功')
        } else {
          console.log(res.msg)
        }
      })
    },
    // 保存项目经历 确认输入
    saveForProjectAction(ref, project_model) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          // 验证通过 调接口
          this.project_dialog = false
          this.saveProject(project_model)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    saveProject(project_model) {
      const idStr = project_model.id
      const pro_name1 = project_model.item_name // 项目名称
      const project_type1 = project_model.project_type // 项目类型
      const item_extent1 = project_model.item_extent // 项目规模数
      const extent_unit1 = project_model.extent_unit // 项目规模单元，㎡等
      const proj_start_date1 = project_model.start_time
      const proj_end_date1 = project_model.end_time
      const position1 = this.proSelectZhuanyeName // 职位 专业
      const major1 = this.proSelectJueseID.length > 0 ? this.proSelectJueseName : this.proJuesInput // 职位 角色
      const desc1 = project_model.desc
      const item_img1 = this.proUploadImageUrl

      console.log(idStr, pro_name1, project_type1, item_extent1, extent_unit1, proj_start_date1,
        proj_end_date1, position1, major1, desc1, item_img1
      )

      const param = { id: idStr, pro_name: pro_name1, project_type: project_type1,
        item_extent: item_extent1, extent_unit: extent_unit1, proj_start_date: proj_start_date1,
        proj_end_date: proj_end_date1, position: position1, major: major1, desc: desc1, item_img: item_img1,token: this.token
      }
      addOrUpdateArchiveProject(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          console.log(res)
          this.$message.success('保存成功')
          this.getUerArchiveInfo()
        }
      }).catch(err => {
        console.log(err)
      })
    },

    // 公开、隐藏 工作经历
    switchWorkEvent() {
      const v = Number(!this.work_switch)
      const param = { name: 'carrers', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.work_switch = !this.work_switch
          console.log('修改 工作经历 状态 ' + this.work_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    requestForSaveWorkItem(formName, work_form) {
      // 保存工作经历
      this.$refs[formName].validate((valid) => {
        if (valid) {
          work_form.isRequest = true
          this.saveWork_info(work_form)
        } else {
          work_form.isRequest = false
          return false
        }
      })
    },
    handleClose() {
      // 弹框消失，清空表单提示
      console.log(this.work_form.isRequest)
      if (this.work_form.isRequest !== undefined && this.work_form.isRequest === true) {
        this.$refs.workRef.clearValidate()
      } else {
        this.$refs.workRef.resetFields()
      }
    },
    saveWork_info(work_form) {
      // 保存工作经历
      const idStr = work_form.id
      const start_time = work_form.start_time
      const company_name = work_form.company_name
      const position = work_form.position
      const desc = work_form.desc
      const end_time = work_form.end_time

      console.log('idStr=' + idStr, 'start_time=' + start_time, 'end_time=' + end_time, 'company_name=' + company_name, 'position=' + position, 'des=' + desc)

      const param = { id: idStr, start_time: work_form.start_time, end_time: work_form.end_time,
        unit_id: work_form.unit_id, position: work_form.position, desc: work_form.desc, company_name: work_form.company_name ,token: this.token}
      addOrUpdateArchiveJob(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.work_dialog = false
          if (idStr !== undefined && idStr.length > 0) {
            this.carrers.splice(work_form.arr_index, 1, work_form)
          } else {
            this.getUerArchiveInfo()
          }
          console.log('工作经历修改成功')
        }
      })
    },
    deleteWork_info(item) {
      // 删除工作经历
      const idStr = item.id
      const param = { id: idStr ,token: this.token}
      deleteArchiveJob(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.carrers.splice(this.carrers.indexOf(item), 1)
          console.log('删除成功')
          this.$message.success('删除成功')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    work_edit_info(item, index) {
      if (item !== undefined && index !== undefined) {
        item.start_time = item.start_time + ''
        item.end_time = item.end_time + ''
        this.work_form = JSON.parse(JSON.stringify(item))
        this.work_form.arr_index = index
      }else{
        this.work_form = {}
      }
      this.work_dialog = true
    },

    // 公开、隐藏 项目经验
    switchExperienceEvent() {
      this.experience_switch = !this.experience_switch
      console.log('修改 项目经验 状态 ' + this.experience_switch)
    },

    // 公开、隐藏 相关论著
    switchSocialEvent() {
      const v = Number(!this.social_switch)
      const param = { name: 'social_work', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.social_switch = !this.social_switch
          console.log('修改 相关论著 状态 ' + this.social_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    inputSocialConfirm() {
      // 添加个人论著内容
      const input = this.social_inputValue
      if (input) {
        this.social_info = input
      }
      // this.social_inputValue = '';
    },
    requestForSaveSocial() {
      // 保存个人论著内容
      this.social_dialog = false
      const socialStr = this.social_inputValue
      const params = { social_work: socialStr ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          // this.social_info = socialStr
          this.$message.success('保存成功')
          this.getUerArchiveInfo()
          
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    deleteSocial_info() {
      // 删除个人论著内容
      const socialStr = ''
      const params = { social_work: socialStr ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('删除成功')
          this.getUerArchiveInfo()
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },

    // 公开、隐藏 个人成就
    switchChengjiuEvent() {
      const v = Number(!this.chengjiu_switch)
      const param = { name: 'achievement', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.chengjiu_switch = !this.chengjiu_switch
          console.log('修改 个人成就 状态 ' + this.chengjiu_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    inputChengjiuConfirm() {
      // 添加个人成就操作
      const input = this.chengjiu_inputValue
      if (input) {
        this.chengjiu_info = input
      }
      // this.chengjiu_inputValue = '';
    },
    requestForSaveChengjiu() {
      // 保存个人成就操作
      this.chengjiu_dialog = false
      const chengjiuStr = this.chengjiu_inputValue
      const params = { achievement: chengjiuStr ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('保存成功')
          this.getUerArchiveInfo()
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    deleteChengjiu_info() {
      // 删除个人成就操作
      const chengjiuStr = ''
      const params = { achievement: chengjiuStr ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('删除成功')
          this.getUerArchiveInfo()  
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },

    // 公开、隐藏 社会职务
    switchPositionEvent() {
      const v = Number(!this.position_switch)
      const param = { name: 'socialduties', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.position_switch = !this.position_switch
          console.log('修改 社会职务 状态 ' + this.position_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    inputPositionConfirm() {
      // 添加社会职务
      const input = this.position_inputValue
      if (input) {
        this.position_info = input
      }
      // this.position_inputValue = '';
    },
    requestForSavePosition() {
      // 保存社会职务
      this.position_dialog = false
      const positionStr = this.position_inputValue
      const params = { socialduties: positionStr ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('保存成功')
          this.getUerArchiveInfo()
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    deletePosition_info() {
      // 删除社会职务
      const positionStr = ''
      const params = { socialduties: positionStr ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.position_inputValue = ''
          this.position_info = ''
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },

    // 公开、隐藏 职业资格证书
    switchCertifyEvent() {
      const v = Number(!this.certify_switch)
      const param = { name: 'certificate', value: v ,token: this.token}
      setPrivacy(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.certify_switch = !this.certify_switch
          console.log('修改 职业资格证书 状态 ' + this.certify_switch)
          this.$message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 删除 职业资格证书
    deleteCertify_info() {
      const certificate = ''
      const params = { certificate: certificate ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('删除成功')
          this.certify_info = ''
          this.certify_info_array = []
          this.certify_inputValue = ''
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    // 删除一个标签(val)
    certify_dialogClose(val) {
      this.certify_info_array.splice(this.certify_info_array.indexOf(val), 1)
      // this.certify_info = this.certify_info_array.join(',')
    },
    // 确认输入
    inputCertifyConfirm() {
      const input = this.certify_inputValue
      if (input) {
        this.certify_info_array.push(input)
        // this.certify_info = this.certify_info_array.join(',')
      }
      this.certify_inputValue = ''
    },
    // 添加标签之后输入框获取光标
    addOneCertifyToList() {
      this.$nextTick(_ => {
        this.$refs.saveCertifyInput.$refs.input.focus()
      })
    },
    requestForSaveCertify() {
      // 操作之后，保存 请求接口
      this.certify_dialog = false
      const certificate = this.certify_info_array.join(',')
      const params = { certificate: certificate ,token: this.token}
      archiveItem(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('保存成功')
          this.getUerArchiveInfo()
          // this.certify_info = ''
          // this.certify_info_array = []
          // this.certify_inputValue = ''
        }
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main_box {
  text-align: left;
  width: 100%;
  min-height: 500px;
  background-color: #fff;
}
.item_title {
  width: 100%;
  height: 40px;
  p {
    float: left;
    text-align: left;
    width: 150px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 40px;
    color: #333333;
    font-weight: 600;
  }
  .item_right {
    text-align: center;
    float: right;
    width: 160px;
    .item_add {
      margin-top: 10px;
      height: 20px;
      line-height: 20px;
      float: left;
      cursor: pointer;
      img {
        margin-top: -2px;
      }
      span {
        margin-left: 10px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #ee2e2f;
      }
    }
    .switch {
      line-height: 20px;
      height: 20px;
      width: 130px;
      margin-top: 10px;
      margin-left: 25px;
    }
  }
}
.item.edit_delete {
  background-color: blue;
}
.tags {
  background-color: #fff;
  padding: 20px 10px 10px 10px;
}
.tag_box {
  width: 100%;
  padding: 15px 0px ;
  text-align: left;
}
.tag_box_other {
  width: 100%;
  padding: 15px 0px ;
  text-align: left;
}
.tag_lists {
  width: 730px;
  padding: 0 0px;
  display: inline-block;
  padding-bottom: 10px;
  .tag_class {
    line-height: 30px;
    padding: 0 10px;
    text-align: left;
    background-color: #f5f5f5;
    border-radius: 15px;
    min-width: 30px;
    font-family: PingFangSC-Regular;
    font-size: 14px !important;
    color: #999999  !important;
    margin: 10px 10px 0px 0px;
    border-width: 0;
    height: auto;
  }
}
.tag_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: 10px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .tag_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .tag_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}
.tag_box:hover {
  .tag_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.tag_input {
  width: 200px;
  margin-top: 30px;
  margin-right: 30px;
}
/deep/ .el-tag {
  line-height: 30px;
  padding: 0 10px;
  text-align: left;
  background-color: #f5f5f5;
  border-radius: 15px;
  min-width: 60px;
  font-family: PingFangSC-Regular;
  font-size: 14px !important;
  color: #999999  !important;
  margin: 10px 10px 0px 0px;
  border-width: 0;
  /// 标签换行
  white-space: pre-line;
  display: inline-block;
  width: auto;
  height: auto;
}
.introl {
  // 个人介绍
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}
.introl_box {
  text-align: left;
  min-height: 50px;
  padding: 15px 0;
  p {
    padding: 0 10px 0 0;
    display: inline-block;
    width: 730px;
    line-height: 30px;
  }
}
.introl_box_other {
  text-align: left;
  min-height: 50px;
  padding: 15px 0;
  p {
    padding: 0 10px;
    display: inline-block;
    width: 730px;
    line-height: 30px;
  }
}
.introl_box:hover {
  .introl_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.introl_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: -10px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .introl_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .introl_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}

.educations {
  // 教育经历
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}
.edu_box{
  background-color: #fff;
  width: 100%;
}
.edu_lists {
  width: 100%;
  text-align: left;
  padding: 15px 15px 15px 0px;
}
.edu_item {
  width: 710px;
  height: 34px;
  display: inline-block;
  span {
    line-height: 30px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666666;
  }
  .span_time {
    display: inline-block;
    width: 130px;
  }
}
.edu_lists_other {
  width: 100%;
  text-align: left;
  padding: 15px;
}
.edu_lists:hover {
  .edu_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.edu_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: 5px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .edu_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .edu_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}

.edu_dialog_class {
  // 教育经历弹框一些设置
  /deep/.el-form-item__label::before{
    // content: "*";
    // color: #ee2e2e;
    margin-right: 4px;
  }
  /deep/.el-dialog__header{
    text-align: left;
    font-weight: bold;
    font-size: 18px !important;
  }
  /deep/.dialog-footer{
    margin-top: -20px;
  }
  .line{
    text-align: center;
  }
}

.projects {
  // 项目经历
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}
.pro_box {
  background-color: #fff;
  display: inline-block;
  text-align: left;
}
.pro_lists {
  width: 100%;
  text-align: left;
  float: left;
}
.pro_lists_other {
  width: 100%;
  text-align: left;
  float: left;
}
.pro_item {
  width: 730px;
  padding: 15px 15px 15px 0px;
  float: left;
}
.thumb {
  width: 160px;
  height: 120px;
  margin-right: 15px;
  float: left;
  img {
    width: 100%;
    height: 100%;
  }
}
.pro_info {
  display: table-row;
  .pro_title {
    font-size: 16px;
    line-height: 30px;
    color: #616161;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .pro_detail {
    font-size: 14px;
    color: #939393;
    line-height: 24px;
  }
  span {
    font-size: 14px;
    color: #939393;
    line-height: 24px;
  }
}
.pro_lists:hover {
  .pro_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.pro_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: 15px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .pro_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .pro_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}
.pro_dialog_class {
  // 项目经历弹框一些设置
  /deep/.el-form-item__label::before{
    // content: "*";
    // color: #ee2e2e;
    margin-right: 4px;
  }
  /deep/.el-dialog__header{
    text-align: left;
    font-weight: bold;
    font-size: 18px !important;
  }
  /deep/.dialog-footer{
    margin-top: -20px;
  }
  .line{
    text-align: center;
  }
  // 上传图片
  /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    float: left;
    overflow: hidden;
  }
  /deep/.el-upload:hover {
    border-color: #409EFF;
  }
  .cus_position {
    // background-color: yellow;
    background-color: #fff;
    position: relative;
    .zhuanye_btn {
      border: 1px solid #DCDFE6;
      padding: 0 10px;
      text-align: left;
      display: inline-block;
      width: 190px;
      line-height: 30px;
      font-size: 16px;
      border-radius: 3px;
      color: #999;
      cursor: pointer;
    }
    .zhuanye_btn_select {
      border: 1px solid #DCDFE6;
      padding: 0 10px;
      text-align: left;
      display: inline-block;
      width: 190px;
      line-height: 30px;
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
    }
    .select_juese {
      margin-left: 15px;
      border: 1px solid #DCDFE6;
      padding: 0 10px;
      text-align: left;
      display: inline-block;
      width: 190px;
      line-height: 30px;
      font-size: 16px;
      color: #999;
      cursor: pointer;
    }
    .input_juese {
      width: 200px;
      margin-left: 15px;
      border:none;
      border: 1px solid #DCDFE6;
      height: 32px;
      line-height: 32px;
      border-radius: 3px;
      padding: 5px;
      outline:none;
    }
    .selec_zy {
      z-index: 1;
      width: 570px;
      height: 350px;
      top: 43px;
      position: absolute;
      background-color: #fff;
      border: 2px solid #ccc;
        .select_zy_top {
          background: none repeat scroll 0 0 #f7f7f7;
          border-bottom: 1px solid #e6e6e6;
          float: left;
          height: 36px;
          line-height: 36px;
          width: 100%;
          .zy_top_jiao {
            background: url("https://passport.zhulong.com/img/indeximg/zhul_lyBg.gif") repeat scroll -321px 0 rgba(0, 0, 0, 0);
            clear: both;
            height: 9px;
            left: 30px;
            overflow: hidden;
            position: absolute;
            top: -9px;
            width: 11px;
            z-index: 9999;
          }
          .zy_top_title {
            color: #444;
            float: left;
            font-size: 16px;
            padding-left: 12px;
          }
          .zy_top_close {
            float: right;
            cursor: pointer;
            .zy_top_close_x {
              background: url("https://passport.zhulong.com/img/indeximg/close.gif") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
              float: left;
              height: 36px;
              width: 36px;
            }
          }

        }
        .cate_box_top {
          float: left;
          padding: 10px 15px;
          line-height: 22px;
          border-bottom: 1px solid #eee;
          color: #f00;
        }
        .cate_box_bg {
          padding: 0 15px;
          width: 570px;
          float: left;
        }
        .cate_box_list {
          padding: 10px 0;
          float: left;
          border-bottom: 1px solid #eee;
          .cate_title {
            line-height: 25px;
            float: left;
            width: 110px;
            text-align: right;
            padding-right: 10px;
          }
          .cate_ul {
            float: left;
            width: 420px;
            // width: 100%;
            line-height: 25px;
            margin-left: 10px;
          }
          .cate_li {
            float: left;
            margin-right: 10px;
            position: relative;
            width: 72px;
            cursor: pointer;
          }
        }
      }

      .selec_jues {
      z-index: 1;
      width: 400px;
      height: 350px;
      margin-left: 205px;
      top: 43px;
      position: absolute;
      background-color: #fff;
      border: 2px solid #ccc;
        .select_jues_top {
          background: none repeat scroll 0 0 #f7f7f7;
          border-bottom: 1px solid #e6e6e6;
          float: left;
          height: 36px;
          line-height: 36px;
          width: 100%;
          .jues_top_jiao {
            background: url("https://passport.zhulong.com/img/indeximg/zhul_lyBg.gif") repeat scroll -321px 0 rgba(0, 0, 0, 0);
            clear: both;
            height: 9px;
            left: 30px;
            overflow: hidden;
            position: absolute;
            top: -9px;
            width: 11px;
            z-index: 9999;
          }
          .jues_top_title {
            color: #444;
            float: left;
            font-size: 16px;
            padding-left: 12px;
          }
          .jues_top_close {
            float: right;
            cursor: pointer;
            .jues_top_close_x {
              background: url("https://passport.zhulong.com/img/indeximg/close.gif") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
              float: left;
              height: 36px;
              width: 36px;
            }
          }

        }
        .cate_box_bg {
          padding: 0 15px;
          width: 390px;
          float: left;
        }
        .cate_box_list {
          padding: 10px 0;
          float: left;
          border-bottom: 1px solid #eee;
          .cate_title {
            line-height: 25px;
            float: left;
            width: 100%;
            text-align: left;
          }
          .ul_div {
            float: left;
            width: 100%;
            .cate_ul {
              float: left;
              width: 320px;
              line-height: 25px;
              margin-right: 10px;
            }
            .cate_li {
              float: left;
              margin-right: 10px;
              position: relative;
              width: 72px;
              cursor: pointer;
            }
          }
        }
      }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .upload_alert {
    display: inline-block;
    margin-left: 10px;
    margin-top: 20px;
    p {
      font-size: 12px;
      color: #999;
      line-height: 20px;
      padding: 10px 0 0 10px;
    }
  }
}

.works {
  // 工作经历
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}

.work_list{
  width: 100%;
  text-align: left;
}
.work_box{
  width: 100%;
  padding: 15px 0;
  text-align: left;
}
.work_box_other{
  width: 100%;
  padding: 15px 0;
  text-align: left;
}
.work_info_line{
    // 用来设置间距的
    width: 30px;
    display: inline-block;
  }
.work_box:hover {
  .work_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.work_info_top{
  line-height: 30px;
  height: 30px;
  width: 730px;
}
.work_info_down{
  line-height: 30px;
  min-height: 30px;
  width: 730px;
  // 控制行数
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 0;
  -webkit-box-orient: vertical;
}
.work_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: 0;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .work_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .work_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}
.work-el-dialog {
  // 工作经历弹框一些设置
  /deep/.el-form-item__label::before{
    // content: "*";
    // color: #ee2e2e;
    margin-right: 4px;
  }
  /deep/.el-dialog__header{
    text-align: left;
    font-weight: bold;
    font-size: 18px !important;
  }
  /deep/.dialog-footer{
    margin-top: -20px;
  }
  .line{
    text-align: center;
  }
}

.experience{
  // 项目经验
  background-color: orange;
}

// ***----------↓↓ 分割线  个人论著样式此处开始 ↓↓---------***
.social{
  // 相关论著
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}
.social_box{
  width: 100%;
  padding: 15px 0;
  text-align: left;
}
.social_box_other{
  width: 100%;
  padding: 15px 0;
  text-align: left;
}
.social_box:hover {
  .social_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.social_info_div {
  // padding: 0 10px 0 0;
  display: inline-block;
  width: 730px;
  margin-top: 10px;
  line-height: 30px;
  // padding-bottom: 10px;
}
.social_info_p {
  // 控制行数
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 0;
  -webkit-box-orient: vertical;
}
.social_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: 15px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .social_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .social_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}
.social-el-dialog{
  /deep/.el-dialog__header{
    text-align: left;
    font-weight: bold;
    font-size: 18px !important;
  }
  /deep/.dialog-footer{
    margin-top: -20px;
  }
  span{
    position: absolute;
    color: #ee2e2e;
    margin-top: 10px;
  }

}

// ***----------↓↓ 分割线  个人成就样式此处开始 ↓↓---------***
.chengjiu {
  // 个人成就
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}

// ***----------↓↓ 分割线  社会职务样式此处开始 ↓↓---------***
.position {
  // 社会职务
  background-color: #fff;
  width: 100%;
  padding: 0 10px 20px;
}
// ***----------↓↓ 分割线 职业资格证书 样式此处开始 ↓↓---------***
.certify{
  // 职业资格证书
  background-color: #fff;
  padding: 0 10px 20px;
  width: 100%;
}
.certify_box {
  text-align: left;
  min-height: 50px;
  padding: 15px 0;
  p {
    padding: 0 10px 0 0;
    display: inline-block;
    width: 730px;
    line-height: 30px;
  }
}
.certify_box_other {
  text-align: left;
  min-height: 50px;
  padding: 15px 0;
  p {
    padding: 0 10px;
    display: inline-block;
    width: 730px;
    line-height: 30px;
  }
}
.certify_box:hover {
  .certify_edit_delete {
    display: block;
  }
  box-shadow: 0px 0px 14px 0px rgba(34, 34, 34, 0.1);
  cursor: pointer;
}
.certify_edit_delete {
  display: none;
  float: right;
  width: 160px;
  margin-left: 15px;
  margin-top: 120;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  span {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ee2e2f;
  }
  img {
    margin-top: -2px;
    margin-right: 8px;
  }
  .certify_edit_delete_l {
    display: inline-block;
    width: 80px;
  }
  .certify_edit_delete_r {
    display: inline-block;
    width: 80px;
  }
}
.certify_input {
  width: 200px;
  margin-top: 30px;
  margin-right: 30px;
}
// show-word-limit遮挡问题
.el-textarea /deep/.el-textarea__inner{
  padding-bottom: 42px;
}
</style>
